import React, { useState } from 'react';
import { Button, Classes, Drawer, Tooltip, Position } from '@blueprintjs/core';
import ElementsProperties from '../ElementsProperties';
import { useDispatch, useSelector } from 'react-redux';

import appConfig from '../../../config';
import { Row, Col } from 'antd';
const PropertiesDrawer =()=> {
  const [state, setState] = useState({
    id: 'raj',
    project: appConfig.project,
  });

   
  const { selectedElement } = useSelector((state) => state.Studio);
  const [show, setShow] = React.useState(true);

  const handleOpen = () => setState({ isOpen: true });
  const handleClose = () => setState({ isOpen: false });
   
  return (
    <div>
      <Button
        onClick={handleOpen}
        className="drawerbuttonss"
        style={{ fontSize: '13px'}}
        minimal={true}
        disabled={selectedElement.disable === false ? false : true}>
        <Tooltip content="Element Properties" position={Position.LEFT_TOP}>
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.96672 2.31208C10.1427 2.2241 10.3498 2.2241 10.5257 2.31208L18.0257 6.06208C18.2375 6.16795 18.3712 6.38436 18.3712 6.62109C18.3712 6.85783 18.2375 7.07424 18.0257 7.18011L10.5257 10.9301C10.3498 11.0181 10.1427 11.0181 9.96672 10.9301L2.46672 7.18011C2.25498 7.07424 2.12123 6.85783 2.12123 6.62109C2.12123 6.38436 2.25498 6.16795 2.46672 6.06208L9.96672 2.31208ZM4.14377 6.62109L10.2462 9.67232L16.3487 6.62109L10.2462 3.56986L4.14377 6.62109Z"
                fill="#343741"
              />
              <path
                d="M2.18721 10.0916C2.34158 9.78285 2.717 9.65771 3.02574 9.81208L10.2462 13.4223L17.4667 9.81208C17.7755 9.65771 18.1509 9.78285 18.3052 10.0916C18.4596 10.4003 18.3345 10.7757 18.0257 10.9301L10.5257 14.6801C10.3498 14.7681 10.1427 14.7681 9.96672 14.6801L2.46672 10.9301C2.15799 10.7757 2.03285 10.4003 2.18721 10.0916Z"
                fill="#343741"
              />
              <path
                d="M3.02574 13.5621C2.717 13.4077 2.34158 13.5328 2.18721 13.8416C2.03285 14.1503 2.15799 14.5257 2.46672 14.6801L9.96672 18.4301C10.1427 18.5181 10.3498 18.5181 10.5257 18.4301L18.0257 14.6801C18.3345 14.5257 18.4596 14.1503 18.3052 13.8416C18.1509 13.5328 17.7755 13.4077 17.4667 13.5621L10.2462 17.1723L3.02574 13.5621Z"
                fill="#343741"
              />
            </g>
          </svg>
        </Tooltip>
      </Button>
      <Drawer
        usePortal={false}
        className="rightSideDrawer"
        hasBackdrop={true}
        defaultIsOpen={true}
        // canOutsideClickClose={true}
        onClose={handleClose}
        {...state}>
        <div>
          <Row style={{ padding: '13px' }}>
            <Col span={22}>
              <h4>Advanced formatting </h4>
            </Col>

            <Col span={2} className="drawerCloseButton">
              <Button onClick={handleClose} icon="cross" minimal={true} />
            </Col>
          </Row>
        </div>
        <div className={Classes.DRAWER_BODY}>
          <ElementsProperties {...this} />
        </div>
      </Drawer>
    </div>
  );

};

export default PropertiesDrawer;
