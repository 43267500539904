import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { elementadd } from '../../../redux/studio/actions';

const AddTextType = () => {
  const dispatch = useDispatch();
  const addTextBox = (subType) => {
    dispatch(
      elementadd({
        code: 'Double click to edit',
        type: 'texts',
        fontFamily: 'Source Serif Pro, serif',
        subType: subType,
      })
    );
  };
  return (
    <div className="addText-container">
      <div style={{ padding: 24 }}>
        <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('text box')}>
          Add a text box
        </Button>
        <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('heading')}>
          Add a heading
        </Button>
        <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('title')}>
          Add a title
        </Button>
        <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('subTitle')}>
          Add a subtitle
        </Button>
        <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('body text')}>
          Add a body text
        </Button>
        <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('bulleted text')}>
          Add bulleted body text
        </Button>
        <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('numbered text')}>
          Add numbered body text
        </Button>
        <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('foodnote')}>
          Add footnote
        </Button>
      </div>
    </div>
  );
};

export default AddTextType;
