import React, {useEffect, useState} from 'react';
import {Form, Input, Select} from 'antd';
// import 'antd/dist/antd.css';
import appConfig from '../../config';
import {Button, Classes, Collapse, Dialog, InputGroup, Label, Menu, MenuDivider, MenuItem, Tag} from '@blueprintjs/core';
import {Col, Row} from 'reactstrap';
import {getAssets} from '../../helpers/apiCalls';
import {useDispatch, useSelector} from 'react-redux';
import {elementChanged, elementUpdated, imageUpdated} from '../../redux/studio/actions';
import {AnticlockIcon, HorizontalflipIcon, LeftIcon, TopIcon, VerticalflipIcon} from '../../ProjectSVG';
import $ from 'jquery';

const {Panel} = Collapse;
const {Option} = Select;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 20,
  },
};
const styleVar = {
  height: '400px',
};

const children = [];

children.push(<Option>Bisma</Option>, <Option>Raziya</Option>);

function ElementsProperties(props) {
  const [assetimage, setAssetimage] = useState([]);
  const [initialdata, setInitialdata] = useState([]);
  const [changedImage, setChangedImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flip, setFlip] = useState(false);
  const [referesh, setReferesh] = useState(true);
  const [showsModel, setShowsModel] = useState(false);

  const [rotate1, setRotate1] = useState('rotate(90deg)');
  const [flipright, setFlipright] = useState('scaleX(-1)');
  const [fliptop, setFliptop] = useState('scaleY(-1)');

  const {selectedElement} = useSelector((state) => state.Studio);
  const selected = Object.keys(selectedElement).length !== 0;
  const [borderStyle, setBorderstyle] = useState(selected && selectedElement.style['border-style']);
  const [textAlign, setTextalign] = useState(selected && selectedElement.style['text-align']);
  const [display, setDisplay] = useState(selected && selectedElement.style['display']);

  const dispatch = useDispatch();
  const {user, error} = useSelector((state) => state.Auth);
  const [value, setValue] = useState(0);

  const [form] = Form.useForm();
  let url = appConfig.project;
  let data = url.slides[0].elements;

  let elemId = selectedElement;
  let top;
  let left;
  let width;
  let height;
  let backgroundColor;
  let opacity;
  let color;
  let index;
  let srcimage;
  let text;
  let transform;
  let name;
  let borderColor;
  let borderRadius;
  let borderWidth;
  let animation = [];
  let imagedisplay = 'block';
  let textdisplay = 'block';
  let embeddisplay = 'none';
  let shapesdisplay = 'block';
  let shapesSizedisplay = 'none';
  let embed;
  embed = elemId.content?.embed;
  top = elemId.position?.top;
  left = elemId.position?.left;

  top = elemId.position?.y;
  left = elemId.position?.x;

  width = parseFloat(elemId.position?.width);
  height = parseFloat(elemId.position?.height);
  backgroundColor = elemId.position?.backgroundColor;
  animation = elemId.styleClasses;
  opacity = elemId.style?.opacity;
  name = elemId.name;
  borderColor = elemId.style?.['border-color'];
  borderRadius = elemId.style?.['border-radius'];
  borderWidth = elemId.style?.['border-width'];
  transform = elemId.style?.['transform'];

  if (elemId.type === 'img') {
    imagedisplay = 'none';
    srcimage = elemId.content?.src;
    color = '';
    backgroundColor = '';
    text = '';
  } else if (elemId.type === 'div') {
    textdisplay = 'none';
    imagedisplay = 'none';
    embeddisplay = 'block';
  } else if (elemId.type === 'shapes') {
    shapesdisplay = 'none';
    shapesSizedisplay = 'block';
  } else {
    textdisplay = 'none';
    color = elemId.style?.color;
    backgroundColor = elemId.style?.backgroundColor;
    srcimage = '';
    text = elemId.content?.text;
  }

  const exampleMenu = (
    <Menu>
      <MenuItem icon="graph" text="Block"/>
      <MenuItem icon="map" text="None"/>
      <MenuDivider/>
    </Menu>
  );

  function handledisplayChange(event) {
    dispatch(elementUpdated(elemId, event, 'display'));
    setDisplay(event);
  }

  const displayChildren = [];
  const displayOptions = appConfig.display;
  for (let i = 0; i < displayOptions.length; i++) {
    displayChildren.push(
      <Option key={displayOptions[i]}>{displayOptions[i] === 'none' ? 'hidden' : 'visible'}</Option>
    );
  }

  function handleborderstyleChange(event) {
    dispatch(elementUpdated(elemId, event, 'border-style'));
    setBorderstyle(event);
  }

  const borderstyleChildren = [];
  const borderstyleOptions = appConfig.borderstyle;
  for (let i = 0; i < borderstyleOptions.length; i++) {
    borderstyleChildren.push(<Option key={borderstyleOptions[i]}>{borderstyleOptions[i]}</Option>);
  }

  function handletextalignChange(event) {
    dispatch(elementUpdated(elemId, event, 'text-align'));
    setTextalign(event);
  }

  const textalignChildren = [];
  const textalignOptions = appConfig.textalign;
  for (let i = 0; i < textalignOptions.length; i++) {
    textalignChildren.push(<Option key={textalignOptions[i]}>{textalignOptions[i]}</Option>);
  }
  useEffect(() => {
    if (Object.keys(selectedElement).length !== '') {
      setBorderstyle(selectedElement?.style?.['border-style']);
      setTextalign(selectedElement?.style?.['text-align']);
      setDisplay(selectedElement.style?.display);
    }
  }, [selectedElement]);

  useEffect(() => {
    // setLoading(true);
    // console.log("raju",getAsset)
    getAsset();
    // getAssets()
    //     .then((res) => {
    //         setLoading(false);
    //     })
    //     .catch((err) => console.log(err));
  }, []);

  const getAsset = () => {
    setLoading(true);

    getAssets()
      .then((res) => {
        setLoading(false);
        const data = res.data;
        setAssetimage(data);
        setInitialdata(data);
        setReferesh(!referesh);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const renderAsset = (item, key) => {
    return (
      <div key={`img${key}`} style={{height: '110px', width: '113px', display: 'flex'}}>
        <img
          style={{borderRadius: '20px', height: '106px'}}
          onClick={() => {
            const addImageUr =
              appConfig.socketURL + item.attributes.asset.data[0].attributes?.url.replace('/', '');
            const width = 20;
            const height =
              (item.attributes.asset.data[0].attributes?.height /
                item.attributes.asset.data[0].attributes?.width) *
              33;
            let dimension = {width, height};
            dispatch(imageUpdated({value: addImageUr, type: 'image', dime: dimension, element: elemId}));
          }}
          src={appConfig.socketURL + item.attributes.asset?.data?.[0]?.attributes?.url.replace('/', '')}
          className="w-100 edit_images-img Assetimage"
          alt=""
        />
      </div>
    );
  };
  const searchImage = (event) => {
    if (event.target.value === '') {
      setAssetimage(initialdata);
    } else {
      const filteredData = assetimage.filter(
        (ele, ind) => ele?.attributes?.asset?.data?.[0]?.attributes?.name === event.target.value
      );
      setAssetimage(filteredData);
    }
  };
  const onChange = (e) => {
    if (e.target.name === 'top' || e.target.name === 'left') {
      let elem = selectedElement;
      let jqElem = $('#' + elem.id);
      let parentOffset = jqElem.offset();
      let d = {x: elem.position.x, y: elem.position.y};
      if (e.target.name === 'top') {
        d.y = e.target.value;
      }
      if (e.target.name === 'left') {
        d.x = e.target.value;
      }
      //or $(this).offset(); if you really just want the current element's offset
      let relX = d.x - parentOffset.left;
      let relY = d.y - parentOffset.top;
      let el = $('#lcanvas');

      let cpos = el.offset();

      let pos = {
        top: d.y - cpos.top,
        left: d.x - cpos.left,
      };

      let elpos = {
        // top: e.pageY  * 100 / el.height()  ,
        // left: e.pageX * 100 / el.width(),

        top: ((pos.top - relY) * 100) / el.height(),
        left: ((pos.left - relX) * 100) / el.width(),
      };

      elem.position.x = d.x;
      elem.position.y = d.y;
      elem.position.top = elpos.top;
      elem.position.left = elpos.left;
      dispatch(elementChanged(elem));
      return;
    }
    if (e.target.name !== 'transform') {
      dispatch(elementUpdated(elemId, e.target.value, e.target.name));
    }
  };
  const onChange90 = (transformRotate, value) => {
    if (rotate1 === 'rotate(90deg)') {
      setRotate1('rotate(180deg)');
    } else if (rotate1 === 'rotate(180deg)') {
      setRotate1('rotate(270deg)');
    } else if (rotate1 === 'rotate(270deg)') {
      setRotate1('rotate(360deg)');
    } else {
      setRotate1('rotate(90deg)');
    }
    dispatch(elementUpdated(elemId, value, transformRotate));
  };
  const onChangeRotate = (e) => {
    const transformValue = e.target.value;
    const transformUpdatedValue = `rotate(${transformValue}deg)`;

    dispatch(elementUpdated(elemId, transformUpdatedValue, 'transformRotate'));
  };
  const onChangeflipright = (transformSkew, value) => {
    if (flipright === 'scaleX(-1)') {
      setFlipright('');
    } else {
      setFlipright('scaleX(-1)');
    }

    dispatch(elementUpdated(elemId, value, transformSkew));
  };
  const onChangefliptop = (transformSkew, value) => {
    // setFlip(true)
    if (fliptop === 'scaleY(-1)') {
      setFliptop('');
    } else {
      setFliptop('scaleY(-1)');
    }

    dispatch(elementUpdated(elemId, value, transformSkew));
  };
  const ChangeImage = () => {
  };
  return (
    <div
      onChange={onChange}
      style={{marginTop: '3px', overflowY: 'scroll', height: 'calc(100% - 6px)', overflowX: 'hidden'}}>
      <Label className="ElementsTitle" style={{marginBottom: '20px'}}>
        Size and Rotation
      </Label>
      <div style={{padding: '12px', marginTop: '-16px', marginBottom: '-20px'}}>
        <div style={{display: shapesdisplay}}>
          <Row>
            <Col>
              <Tag className="greenbuttons">Width</Tag>
              <InputGroup
                asyncControl={true}
                value={width}
                name="width"
                type="number"
                step="any"
                className="PropertiesInput"
              />
            </Col>
            <Col>
              <Tag className="greenbuttons">Height</Tag>
              <InputGroup
                asyncControl={true}
                value={height}
                name="height"
                step="any"
                type="number"
                className="PropertiesInput"
              />
            </Col>
          </Row>
        </div>
        <div style={{display: shapesSizedisplay}}>
          <Row>
            <Col>
              <Tag className="greenbuttons">Shapes Width</Tag>
              <InputGroup
                asyncControl={true}
                value={width}
                name="shapewidth"
                type="number"
                step="any"
                className="PropertiesInput"
              />
            </Col>
            <Col>
              <Tag className="greenbuttons">Shapes Height</Tag>
              <InputGroup
                asyncControl={true}
                value={height}
                name="shapeheight"
                step="any"
                type="number"
                className="PropertiesInput"
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={6}>
            <Tag className="greenbuttons">Rotate</Tag>
            <InputGroup
              asyncControl={true}
              value={value}
              name="transform"
              type="number"
              step="any"
              className="PropertiesInput"
              onChange={onChangeRotate}
            />
          </Col>
          <Col xs={2}>
            <Row>
              <Col>
                <span style={{fontSize: '12px'}}>90</span>
              </Col>
            </Row>
            <Row>
              <Button
                minimal={true}
                name="transform"
                value={rotate1}
                style={{border: '0px'}}
                onClick={() => onChange90('transformRotate', rotate1)}>
                <AnticlockIcon/>
              </Button>
            </Row>
          </Col>
          <Col xs={2}>
            <Row>
              <Col>
                <span style={{fontSize: '12px'}}>Flip</span>
              </Col>
            </Row>
            <Row>
              <Col style={{display: 'flex'}}>
                <Button
                  minimal={true}
                  name="transform"
                  value={flipright}
                  style={{border: '0px'}}
                  onClick={() => onChangeflipright('transformSkew', flipright)}>
                  <VerticalflipIcon/>
                </Button>
                <Button
                  minimal={true}
                  name="transform"
                  value={fliptop}
                  style={{border: '0px'}}
                  onClick={() => onChangefliptop('transformSkew', fliptop)}>
                  <HorizontalflipIcon/>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <hr/>
      <Label className="ElementsTitle" style={{marginTop: '-8px'}}>
        Position
      </Label>
      <div style={{padding: '3px', overflow: 'hidden', marginTop: '-7px'}}>
        <Row style={{padding: '5px'}}>
          <Col>
            <Tag className="greenbuttons">
              <div style={{display: 'flex'}}>
                <span>
                  <div>
                    <TopIcon/>
                  </div>
                </span>
                <span>Top</span>
              </div>
            </Tag>
            <Button
              className="PropertiesInput"
              style={{
                marginLeft: ' 10px',
                height: '28px',
                marginTop: '10px',
                border: '1px solid #a19a9a',
              }}
              asyncControl={true}
              name="vertical-align"
              value={0}
              minimal={true}
              onClick={() => {
                dispatch(elementUpdated(elemId, 0, 'top'));
              }}>
              Top
            </Button>
          </Col>
          <Col>
            <Tag className="greenbuttons">
              <div style={{display: 'flex'}}>
                <span>
                  <div>
                    <LeftIcon/>
                  </div>
                </span>
                <span>Left</span>
              </div>
            </Tag>
            <Button
              className="PropertiesInput"
              style={{
                marginLeft: ' 10px',
                height: '28px',
                marginTop: '10px',
                border: '1px solid #a19a9a',
              }}
              asyncControl={true}
              name="vertical-align"
              value={0}
              minimal={true}
              onClick={() => {
                dispatch(elementUpdated(elemId, 0, 'left'));
              }}>
              Left
            </Button>
          </Col>
        </Row>
      </div>
      <hr/>
      <Label className="ElementsTitle" style={{marginTop: '-8px'}}>
        Style
      </Label>
      <div style={{padding: '13px', marginTop: '-11px'}}>
        <Row style={{paddingBottom: '17px'}}>
          <Col span={12}>
            <Tag className="greenbuttons">Name</Tag>
            <InputGroup
              name="name"
              asyncControl={true}
              value={name}
              style={{height: '24px', fontSize: '11px'}}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Tag className="greenbuttons">Opacity</Tag>
            <InputGroup
              asyncControl={true}
              value={opacity}
              name="opacity"
              min={0.1}
              step="0.1"
              max={1}
              type="number"
              className="PropertiesInput"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Tag className="greenbuttons">Border Color</Tag>
            <InputGroup
              asyncControl={true}
              value={borderColor}
              name="border-color"
              type="color"
              className="PropertiesInput"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Tag className="greenbuttons">Border Radius</Tag>
            <InputGroup
              asyncControl={true}
              value={borderRadius}
              name="border-radius"
              type="number"
              step="any"
              className="PropertiesInput"
            />
          </Col>
          <Col>
            <Tag className="greenbuttons">Border Width</Tag>
            <InputGroup
              asyncControl={true}
              value={borderWidth}
              name="border-width"
              type="number"
              step="any"
              className="PropertiesInput"
            />
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col>
            <Tag className="greenbuttons">Display</Tag>
            <Select name="display" style={{width: '100%'}} value={display} onChange={handledisplayChange}>
              {displayChildren}
            </Select>
          </Col>
          <Col>
            <Tag className="greenbuttons">Border style</Tag>
            <Select
              name="border-style"
              style={{width: '100%'}}
              value={borderStyle}
              onChange={handleborderstyleChange}>
              {borderstyleChildren}
            </Select>
          </Col>
        </Row>
        <hr/>
      </div>

      <div style={{display: textdisplay}}>
        <Label className="ElementsTitle" style={{marginTop: '-19px'}}>
          Image Content
        </Label>
        <div style={{padding: '8px'}}>
          <Row style={{padding: '5px'}}>
            <div id="myDIV" style={{backgroundColor: 'white', marginLeft: '8%', marginBottom: '4%'}}>
              <img
                id="image"
                style={{height: '40px', width: '40px', boxShadow: '2px 2px 4px #888888', objectFit: 'contain'}}
                src={srcimage}
              />
            </div>
          </Row>
          <Row style={{padding: '13px', marginTop: '-36px'}}>
            <Col span={24}>
              <img
                style={{height: '80px', widht: '80px', boxShadow: '2px 2px 4px #888888'}}
                src={changedImage}
              />
              <InputGroup defaultValue={changedImage}/>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <button
                style={{paddingRight: '14px', height: '25px', marginLeft: '14px'}}
                className="secondary-button "
                onClick={() => {
                  setShowsModel(true);
                }}>
                Choose Image
              </button>
            </Col>
          </Row>
        </div>
      </div>

      <div style={{padding: '8px'}}>
        <Row style={{padding: '5px', display: imagedisplay}}>
          <Label className="ElementsTitle">Element Detail</Label>
          <Col span={12}>
            <Tag className="greenbuttons">Text</Tag>
            <InputGroup name="text" asyncControl={true} value={text} className="PropertiesInput"/>
          </Col>
        </Row>
        <Row style={{padding: '5px', display: embeddisplay}}>
          <Label className="ElementsTitle">Embed Detail</Label>
          <Col span={12}>
            <Tag className="greenbuttons">Embed Data</Tag>
            <Input.TextArea
              placeholder="Insert Iframe of Youtube video or Map"
              style={{width: '400px', height: '180px'}}
              value={embed}
              name="embed"
            />
          </Col>
        </Row>
      </div>
      <Dialog
        onClose={() => {
          setShowsModel(false);
        }}
        isOpen={showsModel}
        className="projectDialog">
        <div className={Classes.DIALOG_BODY}>
          <div>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button
                minimal={false}
                icon="cross"
                className="cross_btn"
                onClick={() => {
                  setShowsModel(false);
                }}
              />
            </div>
            <h3 className="image_text">Choose an Image</h3>
            <div
              className="bp4-input-group .modifier"
              style={{width: '200px', marginLeft: '26px', marginTop: '15px'}}>
              <InputGroup
                style={{width: '246px'}}
                className="tabsSearchButton"
                placeholder="Search for Backgrounds"
                type="search"
                leftIcon="search"
                asyncControl={true}
                onChange={(event) => searchImage(event)}
              />
            </div>
            <div className="Assetimageone12">
              <div style={{display: 'flex', flexWrap: 'wrap'}} className="edit_images ">
                {assetimage?.map((item, key) => renderAsset(item, key))}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ElementsProperties;
