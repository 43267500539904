import React, { useState } from 'react';
import { Tabs } from 'antd';
import BuilderLayout from '../BuilderLayout';
import CheckboxFilterWrapper from '../CheckboxFilter/CheckboxFilterWrapper';
import LessonListingTab from './LessonListingTab';
import EditableList from './EditableList';

const LessonBuilder = () => {
  const [appliedFilter, setAppliedFilter] = useState({
  });
  const [pagination, setPagination] = useState({
    start: 0,
    limit: 10
  });
  const tabItems = [
    {
      label: 'Wizard Templates',
      children: <LessonListingTab filter={appliedFilter} setAppliedFilter={setAppliedFilter} pagination={pagination} setPagination={setPagination} />,
    },
    {
      label: 'Editable Resources',
      children: <EditableList filter={appliedFilter} setAppliedFilter={setAppliedFilter} pagination={pagination} setPagination={setPagination} />,
    },
  ];

  const sideData = {
    'Template type': ['Lesson', 'Assembly', 'Game'],
    'Created by': ['Me', 'Teaching community', 'SuperTeach'],
    Themes: ['Colorful shapes', 'Seaside', 'Playground', 'City', 'Jungle', 'Forest', 'Christmas'],
  };

  return (
    <BuilderLayout
      sideComponent={<CheckboxFilterWrapper data={sideData} handleOnCheck={setAppliedFilter} />}
      contentComponent={
        <Tabs className={`primary-custom-tab `} defaultActiveKey="1">
          {tabItems.map(({ label, children, ...rest }, index) => {
            return (
              <Tabs.TabPane
                tab={<h2 className="tab-text primary-header">{label}</h2>}
                key={index}
                {...rest}>
                {children}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      }
    />
  );
};

// const createQuery = (value = null, limit = 10) => {
//     //{title: Array(1)}
//     if (!value)
//         return qs.stringify({
//             limit: limit,
//         });
//     const filters = reMapToFilterObj(value);
//     return qs.stringify(
//         {
//             filters: filters,
//         },
//         {
//             encodeValuesOnly: true, // prettify URL
//         }
//     );
// };

// const reMapToFilterObj = (dataObj) => {
//     const newObj = {};
//     for (let key in dataObj) {
//         newObj[key] = {
//             $contains: dataObj[key],
//         };
//     }
//     return newObj;
// };

export default LessonBuilder;
