import React, { useState, useCallback, useRef, useMemo } from 'react';
import appConfig from '../../config';
import { Card } from './slidesCard';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button, InputGroup } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { slideIndexUpdated, slideadded } from '../../redux/studio/actions';
import { Dialog } from '@blueprintjs/core';
import { Select, Carousel } from 'antd';
import { AddslideIcon } from '../../ProjectSVG';
import '@blueprintjs/core/lib/css/blueprint.css';
import { GreaterArrow } from '../../components/Icon/icons';
import _ from 'lodash';
import SlidePreview from './SlidePreview';
const { Option } = Select;

const Slides = () => {
  const dispatch = useDispatch();
  const { slides } = useSelector((state) => state.Studio);
  const { project } = useSelector((state) => state.Studio);
  let Project = project;
  let data = project.slides;
  const [presetSlides, setPresetSlides] = useState(appConfig.project.slides);
  const [showPopup, setShowPopup] = useState(false);
  const [cards, setCards] = useState(data);
  const slider = useRef(null);

  React.useEffect(() => {
    setCards(data);
    console.log('slider', slider);
    // slider.current.goTo(data.length - 2);
  }, [Project.slides, slides]);

  const togglePopup = () => setShowPopup(!showPopup);
  const moveCard = (dragIndex, hoverIndex) => {
    // setCards((prevCards) =>
    //     update(prevCards, {
    //         $splice: [
    //             [dragIndex, 1],
    //             [hoverIndex, 0, prevCards[dragIndex]],
    //         ],
    //     })
    // );
    let updatedCards = _.cloneDeep(cards); // Make a deep copy of the original array
    let hoverItem = cards[hoverIndex];
    let dragItem = cards[dragIndex];
    updatedCards.splice(hoverIndex, 1, dragItem);
    updatedCards.splice(dragIndex, 1, hoverItem);
    setCards(updatedCards);
    dispatch(slideIndexUpdated(updatedCards));
  };
  const handleSubmit = (slide) => {
    if (!slide?.id) { return null; }
    // console.log("slideId", slide?.id)
    dispatch(slideadded(slide?.id));
    // setCards(Project.slides);
    togglePopup();
  };

  // const menu = (
  //     <Menu
  //         items={[
  //             {
  //                 label: (
  //                     <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
  //                         1st menu item
  //                     </a>
  //                 ),
  //             },
  //             {
  //                 label: (
  //                     <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
  //                         2nd menu item (disabled)
  //                     </a>
  //                 ),
  //                 icon: <SmileOutlined />,
  //                 disabled: true,
  //             },
  //             {
  //                 label: (
  //                     <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
  //                         3rd menu item (disabled)
  //                     </a>
  //                 ),
  //                 disabled: true,
  //             },
  //             {
  //                 danger: true,
  //                 label: 'a danger item',
  //             },
  //         ]}
  //     />
  // );
  const breakpoints = [
    { width: 1, itemsToShow: 1 },
    { width: 50, itemsToShow: 5 },
    { width: 765, itemsToShow: 5 },
    { width: 1200, itemsToShow: 8 },
    { width: 1300, itemsToShow: 10 },
    { width: 1400, itemsToShow: 12 },
    { width: 1500, itemsToShow: 14 },
    { width: 1600, itemsToShow: 16 },
    { width: 1700, itemsToShow: 18 },
  ];
  const searchSlides = (event) => {
    const query = event.target.value;
    if (query) {
      setPresetSlides(
        appConfig.project.slides.filter(
          ({ Meta }) => Meta?.Name?.toLocaleLowerCase().includes(query?.toLocaleLowerCase()) || Meta?.Keywords?.toLocaleLowerCase().includes(query?.toLocaleLowerCase())
        )
      );
    }
    else { setPresetSlides(appConfig.project.slides); }
  };

  const sortChildren = [];
  const sortOptions = ['Date'];
  for (let i = 0; i < sortOptions.length; i++) { sortChildren.push(<Option key={sortOptions[i]}>{sortOptions[i]}</Option>); }

  {/* Helper function to calculate the number of slides to show based on the viewport width */ }

  const getSlidesToShowCount = () => {
    const viewportWidth = window.innerWidth;
    // console.log("getSlides")
    if (viewportWidth >= 1600) {
      return 12;
    } else if (viewportWidth >= 1400) {
      return 10;
    } else if (viewportWidth >= 1200) {
      return 8;
    } else if (viewportWidth >= 765) {
      return 6;
    } else {
      return 4;
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div className='slider-arrow slider-arrow-left' onClick={() => slider.current.prev()}>
        <GreaterArrow width="1.5em" height="2em" />
      </div>
      <div style={{ width: '80%' }}>
        <DndProvider backend={HTML5Backend}>
          <Carousel ref={slider} slidesToShow={getSlidesToShowCount()} dots={false} dotPosition='bottom' slidesToScroll={1} infinite={false} itemClass="carousel-item" responsive={breakpoints}>
            {/* {console.log("cards", cards)} */}
            {cards.filter((item) => item).map((card, index) => (
              <div className="carousel-item" key={index} style={{ width: '110px', marginRight: '10px' }}>
                <Card
                  key={index}
                  index={index}
                  card={card}
                  text={index + 1}
                  style={{ width: '110px' }}
                  moveCard={moveCard}
                />
              </div>
            ))}
          </Carousel>


        </DndProvider>

        {showPopup && (
          <Dialog isOpen={true} onClose={togglePopup} style={{ height: '65vh', width: '80%', maxWidth: 700 }}>
            <div
              style={{
                padding: 24,
                display: 'flex',
                position: 'relative',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '2.2px dashed lightgray',
              }}>
              <span style={{ fontSize: 24, fontWeight: 'bolder', color: 'black' }}>Insert Slide</span>
              <InputGroup
                className="tabsSearchButton"
                placeholder="Search Slide by Keywords"
                type="search"
                leftIcon="search"
                asyncControl={true}
                onChange={(event) => searchSlides(event)}
              />
              <Button
                icon="small-cross"
                onClick={togglePopup}
                style={{
                  width: 24,
                  height: 30,
                  borderRadius: '50%',
                  position: 'absolute',
                  top: -12,
                  right: -12,
                  backgroundColor: 'white',
                }}
              />
            </div>
            <div
              style={{
                display: 'grid',
                padding: 24,
                gridTemplateColumns: 'repeat(auto-fill, minmax(125px, 1fr))',
                gap: 24,
                overflowY: 'auto',
                margin: '12px 0',
              }}>
              {presetSlides.map((slide) => {
                return (
                  <div key={slide.id} className="d-flex flex-column align-items-center gap-2" style={{ width: '100%' }}>
                    <SlidePreview slide={slide} handleSubmit={handleSubmit} />
                    <span style={{ fontSize: 14, fontWeight: 500 }}>{slide?.Meta?.Name}</span>
                  </div>
                );
              })}
            </div>
          </Dialog>
        )}
      </div>
      <div className='slider-arrow slider-arrow-right' onClick={() => slider.current.next()}>
        <GreaterArrow width="1.5em" height="2em" />
      </div>
      <div
        style={{
          marginTop: '10px',
          justifyContent: 'end',
          textAlign: 'center',
          display: 'flex',
          marginLeft: 12,
        }}>
        <Button className="addSlideButton" onClick={togglePopup} style={{ textAlign: 'center' }}>
          <div style={{ marginTop: '7px' }}>
            <AddslideIcon />
            <p style={{ color: '#8D8D8D', fontWeight: '600' }}>Add New Slide</p>
          </div>
        </Button>
      </div>
    </div>
  );
};
export default Slides;
