import React, { useState } from 'react';
import { Button, Classes, Divider } from '@blueprintjs/core';
import ElementsBehaviour from '../components/ElementBehaviour';
import { useSelector } from 'react-redux';
import { ELEMENT_Type } from '../../constants';
import { Drawer } from '../../ui/Drawer/Drawer';

const { TEXT } = ELEMENT_Type;

const Behaviourproperty = () => {
  const { selectedElement } = useSelector((state) => state.Studio);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const handleOpen = () => setIsOpenDrawer(true);
  const handleClose = () => setIsOpenDrawer(false);
  return (
    <div>
      <Button onClick={handleOpen} disabled={selectedElement.disable === false ? false : true} className="animaionaniatecss" minimal={true} style={{ marginTop: '15px' }}>
        <p style={{
          // paddingTop: '15px',
          color: '#717171'
        }}>Behaviour</p>
      </Button>
      {'styleClasses' in selectedElement && (
        <Drawer
          hasBackdrop={false}
          usePortal={false}
          onClose={handleClose}
          className="rightSideDrawer"
          isOpen={isOpenDrawer}
          canOutsideClickClose={true}
          title={<Title type={selectedElement.type} />}
        >
          <Divider />
          <div className={Classes.DRAWER_BODY}>
            <ElementsBehaviour />
          </div>
        </Drawer>
      )}

    </div>
  );
};

const Title = ({ type }) => {
  const text = type === TEXT ? 'Add Behaviour to your Text' : 'Add Behaviour to your Object';
  return <b style={{ fontSize: '13px' }}>{text}</b>;
};

export default Behaviourproperty;
