import React from 'react';
import { useDispatch } from 'react-redux';
import { elementChanged, elementUpdated } from '../../redux/actions';
export const MultilineEdit = ({ value, style, elementObj }) => {
  const [editingValue, setEditingValue] = React.useState(value);
  const dispatch = useDispatch();

  const onChange = (event) => setEditingValue(event.target.value);

  const onKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.target.blur();
    }
  };

  const onBlur = (event) => {
    if (event.target.value.trim() === '') {
      setEditingValue(value);
    } else {
      dispatch(elementUpdated(elementObj, event.target.value, 'text'));
      // setValue(event.target.value);
    }
  };

  const onInput = (target) => {
    if (target.scrollHeight > 33) {
      target.style.height = '5px';
      target.style.height = target.scrollHeight + 'px';
    }
  };

  const textareaRef = React.useRef();

  React.useEffect(() => {
    onInput(textareaRef.current);
  }, [onInput, textareaRef]);

  return (
    <textarea
      className="edit-textarea"
      style={{ ...style, resize: 'none' }}
      rows={1}
      aria-label="Field name"
      value={editingValue}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onInput={(event) => onInput(event.target)}
      ref={textareaRef}
      placeholder={elementObj?.content?.placeholder || ''}
      onMouseDown={(e) => dispatch(elementChanged(elementObj))}
    />
  );
};
