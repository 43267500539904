import React from 'react';
import {useSelector} from 'react-redux';
import {message} from 'antd';
import {editProject, editTemplate, editTheme} from '../../../helpers/apiCalls';

function Save() {
  const {project, projectid, projectName, title} = useSelector((state) => state.Studio);
  const save = () => {
    const obj = project;
    const JSONproject = JSON.stringify(obj);

    if (projectName === 'template') {
      let data = {
        data: {
          title: title,
          template: JSONproject,
        },
      };
      editTemplate(projectid, data)
        .then((res) => {
          message.success('Project Saved!');
        })
        .catch((err) => {
          message.error('Project not saved, Plz try again!');
        });
    } else if (projectName === 'theme') {
      let data = {
        data: {
          title: title,
          theme: JSONproject,
        },
      };
      editTheme(projectid, data)
        .then((res) => {
          message.success('Project Saved!');
        })
        .catch((err) => {
          message.error('Project not saved, Plz try again!');
        });
    } else {
      let data = {
        data: {
          title: title,
          project: JSONproject,
        },
      };
      editProject(projectid, data)
        .then((res) => {
          message.success('Project Saved!');
        })
        .catch((err) => {
          message.error('Project not saved, Plz try again!');
        });
    }
  };

  return (
    <button className="secondary-button " onClick={save}>
      Save
    </button>
  );
}

export default Save;
