import React, { useState } from 'react';
import ElementAnimation from './ElementAnimation';
import { Button, Classes, Divider } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { Drawer } from '../../ui/Drawer/Drawer';
import { ELEMENT_Type } from '../../constants';
const { IMG } = ELEMENT_Type;

const AnimationProperty = () => {

  const { selectedElement } = useSelector((state) => state.Studio);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleOpen = () => setIsDrawerOpen(true);
  const handleClose = () => setIsDrawerOpen(false);

  return (
    <div>

      <Button disabled={selectedElement.disable === false ? false : true} onClick={handleOpen} className="animaionaniatecss" minimal={true} style={{ marginTop: '15px' }}>
        <span style={{
          // paddingTop: '15px',
          color: '#717171'
        }}>Animate</span>
      </Button>

      {
        'styleClasses' in selectedElement && (
          <Drawer
            hasBackdrop={false}
            usePortal={false}
            onClose={handleClose}
            className="rightSideDrawer"
            isOpen={isDrawerOpen}
            canOutsideClickClose={true}
            title={<Title type={selectedElement.type} />}
          >
            <Divider />
            <div className={Classes.DRAWER_BODY}>
              <ElementAnimation />
            </div>
          </Drawer>
        )
      }
    </div>
  );
};

const Title = ({ type }) => {
  const text = type === IMG ? 'Add Object Animation' : 'Add Text Animation';
  return (
    <b style={{ fontSize: '13px' }}> {text}</b>
  );
};

export default AnimationProperty;
