import React, {useEffect, useState} from 'react';
import {Button} from '@blueprintjs/core';
import appConfig from '../../../config';
import {getAssets} from '../../../helpers/apiCalls';
import {Select} from 'antd';

export const Capitalize = (stringData) => stringData.charAt(0).toUpperCase() + stringData.slice(1);

function AddImage({imageSelected, id}) {
  const {Option} = Select;
  const [contentType, setContenttype] = useState('background');
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = React.useState(false);
  // const [assetCard, setAssetCard] = useState([<span key="{item}" className="loader"></span>]);
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useState({
    searchText: '',
    searchedColumn: '',
  });

  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
  });

  const sortChildren = [];
  const sortOptions = appConfig.sortAssetList;
  for (let i = 0; i < sortOptions.length; i++) {
    sortChildren.push(<Option key={sortOptions[i]}>{Capitalize(sortOptions[i])}</Option>);
  }
  // const sort = () => {
  //     const changedEvent = handleSortChange();
  //     setContenttype(changedEvent);
  //     // console.log('data336', changedEvent);
  //     getAsset({ pagination });
  // };
  const ap = async (event) => {
    await setContenttype(event);
  };
  const handleSortChange = (event) => {
    console.log('eventdata', event);
    ap(event);
    setContenttype(event);
    setPagination((currentState) => {
      return {...currentState, current: 1};
    });
  };


  // const makeData = (res) => {
  //     let d = [...res];

  //     if (contentType === '') {
  //         const assetdata = d.map((item, index) => {
  //             if (item.attributes.asset.data) {
  //                 return (
  //                     <img
  //                         key={item.id}
  //                         className={item.id === id ? 'dragResizingActive image-shadow' : 'image-shadow'}
  //                         onClick={() => {
  //                             // let calu=item.
  //                             const addImageUrl =
  //                                 appConfig.socketURL +
  //                                 item.attributes.asset.data[0].attributes?.url.replace('/', '');
  //                             const width = item.attributes.asset.data[0].attributes?.width;
  //                             const height = item.attributes.asset.data[0].attributes?.height;
  //                             let cal = { width, height };
  //                             imageSelected(item.id, addImageUrl);
  //                         }}
  //                         alt="bgimage"
  //                         src={appConfig.socketURL + item.attributes.asset.data[0].attributes?.url.replace('/', '')}
  //                     />
  //                 );
  //             } else return null;
  //         });
  //         setAssetCard(assetdata);
  //     } else {
  //         //For Cards
  //         const selectedAssets = d.filter((item) => item.attributes.contenttype === contentType);
  //         console.log('selectasset', selectedAssets);
  //         const assetdata = selectedAssets.map((item, index) => {
  //             if (item.attributes.asset.data) {
  //                 return (
  //                     <img
  //                         key={item.id}
  //                         onClick={() => {
  //                             const addImageUrl =
  //                                 appConfig.socketURL +
  //                                 item.attributes.asset.data[0].attributes?.url.replace('/', '');
  //                             const width = item.attributes.asset.data[0].attributes?.width;
  //                             const height = item.attributes.asset.data[0].attributes?.height;
  //                             let cal = { width, height };
  //                             imageSelected(item.id, addImageUrl);
  //                         }}
  //                         alt="bgimage"
  //                         className={item.id === id ? 'dragResizingActive image-shadow' : 'image-shadow'}
  //                         src={appConfig.socketURL + item.attributes.asset.data[0].attributes?.url.replace('/', '')}
  //                     />
  //                 );
  //             }
  //         });
  //         setAssetCard(assetdata);
  //     }
  // };
  const getRandomuserParams = (params) => ({
    limit: params.pagination.pageSize,
    start:
      params.pagination.current > 1
        ? params.pagination.current * params.pagination.pageSize - params.pagination.pageSize
        : 1,
    ...params,
  });
  const getAsset = (params, events) => {
    setLoading(true);
    const {limit, start} = getRandomuserParams(params);
    getAssets('', limit, start, events, search.searchedColumn, contentType).then((res) => {
      setAssets(res?.data);
      console.log('response', res?.data);
      setLoading(false);
    });
  };

  const handleChangePages = () => {
    setPagination({
      current: 1 + pagination.current,
      pageSize: 10,
    });
  };

  useEffect(() => {
    if (contentType) {
      setLoading(true);
      getAsset({pagination});
    }

  }, [contentType, pagination.current]);

  return (
    <div className="drawer-container">
      <div>
        <Select
          className="projectsSelect"
          name="status"
          style={{width: '100%'}}
          placeholder=" Content Type"
          defaultValue={'background'}
          onChange={handleSortChange}>
          {sortChildren}
        </Select>
      </div>

      <div className="photos-menu-conatiner">
        {loading ? (
          <div className="loading-container">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            {assets?.map((item, index) => {
              if (item.attributes.asset.data) {
                const isSelected = item.id === id;
                return (
                  <div className="photos-menu-image" key={index}>
                    <img
                      key={item.id}
                      className={`image-shadow ${isSelected ? 'tickmark' : null}`}
                      style={{}}
                      onClick={(ev) => {
                        ev.stopPropagation();
                        const addImageUrl =
                          appConfig.socketURL +
                          item.attributes.asset.data[0].attributes?.url.replace('/', '');

                        const width = 20;
                        const height =
                          (item.attributes.asset.data[0].attributes?.height /
                            item.attributes.asset.data[0].attributes?.width) *
                          33;
                        let dimension = {width, height};
                        imageSelected(item.id, addImageUrl, dimension);
                        // dispatch(elementadd(addImageUrl, cal));
                      }}
                      alt={'Bg-Image' + index}
                      src={
                        appConfig.socketURL +
                        item.attributes.asset.data[0].attributes?.url.replace('/', '')
                      }
                    />
                    {isSelected && <span className="tickmark">&#10004;</span>}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </>
        )}
      </div>

      {assets?.length >= 10 ? (
        <Button className="loadMoreBtn" onClick={handleChangePages}>
          Load more
        </Button>
      ) : (
        <p className="fw-2 text-center">
          No data to display{' '}
          <span
            className="fw-3 text-success cursor-pointer"
            onClick={(ev) => {
              ev.stopPropagation();
              setPagination({
                current: 1,
                pageSize: 10,
              });
            }}>
                        Reset
          </span>
        </p>
      )}
    </div>
  );
}

export default AddImage;
