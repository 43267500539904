import React, { useEffect, useState } from 'react';
import { Button, InputGroup } from '@blueprintjs/core';
import { useDispatch } from 'react-redux';
import { elementadd } from '../../../redux/studio/actions';
import appConfig from '../../../config';
import { getAssets } from '../../../helpers/apiCalls';
import { Select } from 'antd';

function AddButton({ buttonSelected, id }) {
  const [loading, setLoading] = React.useState(false);
  const [initialdata, setInitialdata] = useState([]);
  const [assets, setAssets] = useState([]);
  const [events, setEvents] = useState([]);
  let tempButton = appConfig.elemButton;
  const [referesh, setReferesh] = useState(true);
  const { Option } = Select;
  const [contentType, setContenttype] = useState('button');
  const [assetCard, setAssetCard] = useState([<div key="{item}" className="loader"></div>]);
  const dispatch = useDispatch();
  const [search, setSearch] = useState({
    searchText: '',
    searchedColumn: '',
  });
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
  });

  const getRandomuserParams = (params) => ({
    limit: params.pagination.pageSize,
    start:
      params.pagination.current > 1
        ? params.pagination.current * params.pagination.pageSize - params.pagination.pageSize
        : 1,
    ...params,
  });

  const getAsset = (params, events) => {
    setLoading(true);
    const { limit, start } = getRandomuserParams(params);
    getAssets('', limit, start, events, search.searchedColumn, contentType).then((res) => {
      // let data = makeData(res?.data);
      setAssets(res?.data);
      setInitialdata(res?.data);
      setLoading(false);
    });
  };
  const handleChangePages = (ev) => {
    ev.stopPropagation();
    setPagination({
      current: 1 + pagination.current,
      pageSize: 10,
    });
  };


  const makeData = (res) => {
    let d = [...res];

    //For Cards
    const selectedAssets = d.filter((item) => item.attributes.contenttype === contentType);
    const assetdata = selectedAssets.map((item, index) => {
      return (
        <img
          key={index}
          onClick={() => {
            const addImageUrl =
              appConfig.socketURL + item.attributes.asset.data[0].attributes?.url.replace('/', '');
            dispatch(elementadd(addImageUrl));
          }}
          className="image-shadow "
          src={appConfig.socketURL + item.attributes.asset.data[0].attributes?.url.replace('/', '')}></img>
      );
    });
    setAssetCard(assetdata);
  };
  useEffect(() => {
    if (contentType) {
      setLoading(true);
      getAsset({ pagination });
    }
  }, [contentType, pagination.current]);

  const imageCategory = [];
  const imageCategoryOptions = ['buttons', 'background', 'forExtraction', 'GIF', 'Icons'];
  for (let i = 0; i < imageCategoryOptions.length; i++) {
    imageCategory.push(<Option key={imageCategoryOptions[i]}>{imageCategoryOptions[i]}</Option>);

    const searchbutton = (event) => {
      console.log('data', event.target.value);
      if (contentType) {
        setLoading(true);
        getAsset({ pagination }, event.target.value);
      }
      // if (event.target.value === '') {
      //     // setAssets(initialdata);
      // } else {
      //     // const filteredData = assets.filter(ele => {
      //     //     return ele?.attributes?.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
      //     //   });
      //     // // const filteredData = assetCard.filter(
      //     // //     (ele, ind) => ele?.attributes?.asset?.data?.[0]?.attributes?.name === event.target.value
      //     // // );
      //     // setAssets(filteredData);
      //     console.log("dataall",filteredData,assets)
      // }
    };
    return (
      <div className="drawer-container">
        <InputGroup
          className="tabsSearchButton"
          placeholder="Search for Button"
          type="search"
          leftIcon="search"
          asyncControl={true}
          onChange={(event) => searchbutton(event)}
        />
        <div className="photos-menu-conatiner">
          {loading ? (
            <div className="loading-container">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              {assets.map((item, key) => {
                if (item.attributes.asset.data) {
                  const isSelected = item.id === id;
                  return (
                    <div className="photos-menu-image" key={key}>
                      <img
                        key={item.id}
                        className={`image-shadow ${isSelected ? 'tickmark' : null}`}
                        style={{ aspectRatio: '1/1' }}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          const addImageUrl =
                            appConfig.socketURL +
                            item.attributes.asset.data[0].attributes?.url.replace('/', '');
                          // dispatch(elementadd(addImageUrl));

                          const width = 20;
                          const height =
                            (item.attributes.asset.data[0].attributes?.height /
                              item.attributes.asset.data[0].attributes?.width) *
                            33;
                          let dimension = { width, height };
                          buttonSelected(item.id, addImageUrl, dimension);
                        }}
                        alt="btnIMage"
                        src={
                          appConfig.socketURL +
                          item.attributes.asset.data[0].attributes?.url.replace('/', '')
                        }
                      />
                      {isSelected && <span className="tickmark">&#10004;</span>}
                    </div>
                  );
                }
              })}
            </>
          )}
        </div>
        {assets.length >= 10 ? (
          <Button className="loadMoreBtn" onClick={handleChangePages}>
            Load more
          </Button>
        ) : (
          <p className="fw-2 text-center">
            No data to display{' '}
            <span
              className="fw-3 text-success cursor-pointer"
              onClick={(ev) => {
                ev.stopPropagation();
                setPagination({
                  current: 1,
                  pageSize: 10,
                });
              }}>
              Reset
            </span>
          </p>
        )}
      </div>
    );
  }
}

export default AddButton;
