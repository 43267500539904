import React from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu, X } from 'react-feather';
import { showRightSidebar } from '../../redux/actions';
import { Alignment, Navbar, NavbarGroup, Button } from '@blueprintjs/core';
import { DropdownIcon, LogoIcon } from '../../ProjectSVG';
import AccountDetail from '../AccountDetail';
import Save from '../../studio/components/StudioTop/Save';
import Preview from '../../studio/components/StudioTop/Preview';
import Publish from '../../studio/components/StudioTop/Publish';
import ProjectTitle from '../../studio/components/ProjectTitle';
import Projectproperty from '../../studio/components/Projectproperty';
import { Col, Popover, Row } from 'antd';
import { menuItems } from '../../constants/menuItems';
import styles from './styles.module.css';
import PopoverMenu from './PopoverMenu';
import upgrade from '../../assets/images/upgrade.png';

const Topbar = ({ mainTopbar = true, openLeftMenuCallBack = () => null, showRightSidebar = () => null }) => {
  const history = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const searchObject = Object.fromEntries(searchParams.entries());
  console.log('currentPath', location, searchParams, searchObject);
  return (
    <React.Fragment>
      <Navbar>
        <NavbarGroup align={Alignment.CENTER}>

          {mainTopbar ? (
            <div className={`navbar navbar-expand flex-column flex-md-row navbar-custom ${styles.navbarCustomised} ${styles.appmenu_topbar}`}>
              <Col style={{ paddingLeft: '15px' }}>
                <Link to="/" className="navbar-brand logo">
                  <span className="logo-lg">
                    <LogoIcon />
                  </span>
                  <span className="logo-sm">
                    <LogoIcon />
                  </span>
                </Link>
                <ul className="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0">
                  <li className="">
                    <button
                      className="button-menu-mobile open-left disable-btn"
                      onClick={openLeftMenuCallBack}>
                      <Menu className="menu-icon" />
                      <X className="close-icon" />
                    </button>
                  </li>
                </ul>
              </Col>
              <Row className="gap-4 justify-content-center align-items-center">
                <Col>
                  <ul className="unorder-list">
                    {menuItems.map((item, id) => (
                      <li
                        onClick={() => item?.route && history(item.route)}
                        key={id}
                      >
                        <Popover content={item?.content ? <PopoverMenu content={item?.content} /> : null}>
                          <span>{item.name}</span>
                          {item.isDrop ? (<DropdownIcon />) : null}
                        </Popover>
                      </li>
                    ))}
                  </ul>

                </Col>
                <Col>
                  <span style={{ display: 'flex', width: '119px', height: '39px' }}>
                    <img src={upgrade} /></span></Col>
                <Col>
                  <AccountDetail />
                </Col>
              </Row>
            </div>
          ) : (
            <div className="navbar navbar-expand flex-column flex-md-row navbar-custom navbarCustomised appmenu_topbar">
              <Col className="HomeTopText">
                <Button
                  className="HomeButton"
                  style={{ height: '32px' }}
                  onClick={() => history(`/my-creation`)}>
                  Home
                </Button>
                <Projectproperty />
                <ProjectTitle />
              </Col>

              <Col>
                <div className="HomeButtons">
                  <Save />
                  <Preview disabled={searchObject?.type === 'template'} />
                  <Publish />
                  <AccountDetail />
                </div>
              </Col>
            </div>
          )}
        </NavbarGroup>
      </Navbar>
    </React.Fragment>
  );
};

export default connect(null, { showRightSidebar })(Topbar);
