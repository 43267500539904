import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { elementUpdated } from '../../redux/studio/actions';
import { Button, Tooltip, Position } from '@blueprintjs/core';
import { Minus, Plus } from '../../ProjectSVG';
import CustomTextColor from '../../components/CustomTextColor';


const ToolbarDrawer = () => {
  const dispatch = useDispatch();
  const { selectedElement: elemId } = useSelector((state) => state.Studio);
  // const elemId = selectedElement;

  const getStyle = {
    backgroundColor: elemId.style?.backgroundColor ? elemId.style?.backgroundColor : '#ffffff',
    textDisplay: 'block',
    fontSize: elemId.style?.['font-size'] ? elemId.style?.['font-size'] : 0,
    fontFamily: elemId.style?.['font-family'] ? elemId.style?.['font-family'] : '',
    fontWeight: elemId.style?.['font-weight'] ? elemId.style?.['font-weight'] : 400,
    fontStyle: elemId.style?.['font-style'] ? elemId.style?.['font-style'] : 'normal',
    textDecoration: elemId.style?.['text-decoration'] ? elemId.style?.['text-decoration'] : 'none',
    color: elemId.style?.color ? elemId.style?.color : '#000000',
    textAlign: elemId.style?.['text-align'] ? elemId.style?.['text-align'] : 'left',
    srcImage: elemId.type === 'img' ? elemId.content?.src : '',
    text: elemId.content?.text,
    textTranform: elemId.style?.['text-transform'] ? elemId.style?.['text-transform'] : 'inherit',
  };

  const handleMinus = (e) => {
    e.preventDefault();
    dispatch(elementUpdated(elemId, --e.target.value, e.target.name));
  };
  const handlePlus = (e) => {
    e.preventDefault();
    dispatch(elementUpdated(elemId, ++e.target.value, e.target.name));
  };
  const handleChangeFont = (e) => {
    dispatch(elementUpdated(elemId, e.target.value, e.target.name));

  };

  const onChange = (e) => {
    e.preventDefault();
    dispatch(elementUpdated(elemId, e.target.value, e.target.name));
  };

  const onChangeAlphabet = (value) => {
    let changedValue = value === 'uppercase' ? 'inherit' : 'uppercase';
    dispatch(elementUpdated(elemId, changedValue, 'text-transform'));
  };

  const onChangeBold = (value) => {
    let changedValue = value === 700 ? 400 : 700;
    dispatch(elementUpdated(elemId, changedValue, 'font-weight'));
  };
  const onChangeItalic = (value) => {
    let changedValue = value === 'italic' ? 'normal' : 'italic';
    dispatch(elementUpdated(elemId, changedValue, 'font-style'));
  };
  const onChangeUnderline = (value) => {
    let changedValue = value === 'underline' ? 'none' : 'underline';
    dispatch(elementUpdated(elemId, changedValue, 'text-decoration'));
  };

  const onChangeTextAlign = (incomingValue) => {
    dispatch(elementUpdated(elemId, incomingValue, 'text-align'));
  };


  return (
    <div style={{ padding: '0 10px' }}>
      {(elemId.type === 'text') && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', gap: 24 }}>
            <select
              name="font-family"
              onChange={onChange}
              // placement="bottom"
              className="TextStylingButtonsection"
              value={getStyle.fontFamily}>
              <option value="serif">Serif</option>
              <option value={'Roboto, "Helvetica Neue", sans-serif'}>
                'Roboto, "Helvetica Neue", sans-serif'
              </option>
              <option value="cursive">Cursive</option>
              <option value="Garamond ">Garamond</option>
              <option value="Helvetica">Helvetica</option>
              <option value="Georgia ">Georgia </option>
              <option value="Trebuchet MS">Trebuchet MS</option>
              <option value={' "Lato Black Charset0_v8M0F0A136C","Lato Black", "Lato",sans-serif'}>
                '"Lato Black Charset0_v8M0F0A136C","Lato Black", "Lato",sans-serif'{' '}
              </option>
              <option value={' "Lato Semibold CharsboldB0C87B2D", "Lato Semibold", "Lato", sans-serif'}>
                'Lato Semibold CharsboldB0C87B2D", "Lato Semibold", "Lato", sans-serif'{' '}
              </option>
            </select>
            <div className="wrapper">
              <button
                className="plusminus"
                value={getStyle.fontSize}
                name="font-size"
                onClick={handleMinus}>
                <Minus />
              </button>
              <div style={{ borderRight: '1px solid #dedede', height: '100%' }} />
              <input
                type="text"
                className="num"
                name="font-size"
                value={getStyle.fontSize}
                // asynccontrol="true"
                onChange={(e) => handleChangeFont(e)}
              />
              <div style={{ borderRight: '1px solid #dedede', height: '100%' }} />
              <button
                className="plusminus"
                value={getStyle.fontSize}
                name="font-size"
                onClick={handlePlus}>
                <Plus />
              </button>
            </div>
          </div>
          <div style={{ display: 'flex', gap: 5, transform: 'translateY(-2px)' }}>
            <Tooltip content="Bold" position={Position.BOTTOM}>
              <Button
                className="ToolBarICons"
                style={{
                  fontWeight: '700',
                }}
                active={getStyle.fontWeight === 700}
                name="font-weight"
                minimal={true}
                value={getStyle.fontWeight}
                onClick={(e) => onChangeBold(getStyle.fontWeight)}>
                B
              </Button>
            </Tooltip>
            <Tooltip content="Italic" position={Position.BOTTOM}>
              <Button
                style={{
                  fontStyle: 'italic',
                }}
                className="ToolBarICons"
                // icon="italic"
                active={getStyle.fontStyle === 'italic'}
                minimal={true}
                name="font-style"
                value={getStyle.fontStyle}
                onClick={(e) => onChangeItalic(getStyle.fontStyle)}>
                I
              </Button>
            </Tooltip>
            <Tooltip content="Underline" position={Position.BOTTOM}>
              <Button
                style={{
                  textDecoration: 'underline',
                }}
                className="ToolBarICons"
                active={getStyle.textDecoration === 'underline'}
                minimal={true}
                value={getStyle.textDecoration}
                name="text-decoration"
                onClick={(e) => onChangeUnderline(getStyle.textDecoration)}>
                U
              </Button>
            </Tooltip>

            <Tooltip content="Change Case" position={Position.BOTTOM}>
              <Button
                className="ToolBarICons"
                minimal={true}
                style={{ fontSize: '14px', fontWeight: '700' }}
                active={getStyle.textTranform === 'uppercase'}
                value={getStyle.textTranform}
                onClick={(e) => onChangeAlphabet(getStyle.textTranform)}
                name="text-transform">
                aA
              </Button>
            </Tooltip>
            <Tooltip content="fontColor" position={Position.BOTTOM}>
              <div style={{}}>
                {/* <button type="button" className="butoncss">
                                <FontColorsOutlined
                                    style={{
                                        width: '20px',
                                        padding: '0px',
                                        marginLeft: '17px',
                                        textAlign: 'center',
                                        display: ' block',
                                    }}
                                />
                            </button> */}
                {/* <input
                  type="color"
                  value={elemId.style?.color ? elemId.style?.color : '#000000'}
                  name="color"
                  // asynccontrol="true"
                  className="fontColorButton"
                  onChange={onChange}
                  style={{ display: 'none' }}
                /> */}
                <CustomTextColor elemId={elemId} />
              </div>
            </Tooltip>

            <Tooltip content="Align-center" position={Position.BOTTOM}>
              <Button
                className="ToolBarICons"
                icon="align-center"
                name="text-align"
                style={{ transform: 'translateY(-2px)' }}
                minimal={true}
                active={getStyle.textAlign === 'center'}
                value={getStyle.textAlign}
                onClick={(e) => onChangeTextAlign('center')}
              />
            </Tooltip>
            <Tooltip content="Align-left" position={Position.BOTTOM}>
              <Button
                className="ToolBarICons"
                style={{ transform: 'translateY(-2px)' }}
                icon="align-left"
                name="text-align"
                minimal={true}
                active={getStyle.textAlign === 'left'}
                value={getStyle.textAlign}
                onClick={(e) => onChangeTextAlign('left')}
              />
            </Tooltip>
            <Tooltip content="Align-right" position={Position.BOTTOM}>
              <Button
                className="ToolBarICons"
                style={{ transform: 'translateY(-2px)' }}
                icon="align-right"
                active={getStyle.textAlign === 'right'}
                name="text-align"
                minimal={true}
                value={getStyle.textAlign}
                onClick={(e) => onChangeTextAlign('right')}
              />
            </Tooltip>
            <Tooltip content="Align-justify" position={Position.BOTTOM}>
              <Button
                className="ToolBarICons"
                style={{ transform: 'translateY(-2px)' }}
                icon="align-justify"
                active={getStyle.textAlign === 'justify'}
                name="text-align"
                minimal={true}
                value={getStyle.textAlign}
                onClick={(e) => onChangeTextAlign('justify')}
              />
            </Tooltip>
            <Tooltip className="background123" position={Position.BOTTOM} content="Shading">
              <CustomTextColor elemId={elemId} type={'backgroundColor'} />
            </Tooltip>
          </div>
          {/* <div style={{ marginTop: '1rem' }}>
            <TextArea
              value={selectedElement?.content.text}
              placeholder="Type something..."
              className="txt-input"
              onChange={(e) => textHandler(e)}
            />
          </div>
          <div className="rmv-shadow">
            <Button className="deltext-btn" onClick={() => props.deleteElementText(selectedElement)}>
              Delete
            </Button>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default ToolbarDrawer;
