import React, { useState } from 'react';
import { Label } from '@blueprintjs/core';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { elementUpdated, elementChanged } from '../../redux/studio/actions';
import { Button } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import rotate1 from '../../assets/images/rotate1.png';
import { Menu as LolMenu, Item, Separator, Submenu, useContextMenu } from 'react-contexify';
import { Minus, Plus, Cropbtn, assetRotate } from '../../ProjectSVG';
import { BackwardIcon, AnticlockIcontwo, Clock90Icon, FlipHorizontal, FlipVertical } from '../../ProjectSVG';
const Imagerotate = (props) => {
  const { selectedElement } = useSelector((state) => state.Studio);
  const dispatch = useDispatch();
  let elemId = selectedElement;

  const [flipright, setFlipright] = useState('scaleX(-1)');
  const [fliptop, setFliptop] = useState('scaleY(-1)');
  const handleClockWise = ({ event, props }) => {

    if (elemId.style.transformRotate.length) {
      let angle = elemId.style.transformRotate.split('(')[1].split('d')[0];
      if (typeof parseInt(angle) === 'number') {
        angle = parseInt(angle) + 90;
        return angle <= 360
                    ? dispatch(elementUpdated(elemId, `rotate(${angle}deg)`, 'transformRotate'))
                    : dispatch(elementUpdated(elemId, `rotate(0deg)`, 'transformRotate'));
      } else {
        console.log('no  ides', angle);
      }
    } else {
      dispatch(elementUpdated(elemId, 'rotate(90deg)', 'transformRotate'));
    }
    return;
  };
  const handleCounterClockWise = ({ event, props }) => {

    if (elemId.style.transformRotate.length) {
      let angle = elemId.style.transformRotate.split('(')[1].split('d')[0];
      if (typeof parseInt(angle) === 'number') {
        angle = parseInt(angle) - 90;
        return angle <= -360
                    ? dispatch(elementUpdated(elemId, `rotate(0deg)`, 'transformRotate'))
                    : dispatch(elementUpdated(elemId, `rotate(${angle}deg)`, 'transformRotate'));
      } else {
        console.log('no ide anto', angle);
      }
    } else {
      dispatch(elementUpdated(elemId, 'rotate(-90deg)', 'transformRotate'));
    }
    return;
  };
  const onChangeflipright = ({ event, props }) => {
    if (elemId.style.transformSkew === 'scaleX(-1)') {
      dispatch(elementUpdated(elemId, '', 'transformSkew'));
    } else if (elemId.style.transformSkew === 'scaleY(-1)') {
      dispatch(elementUpdated(elemId, 'scale(-1)', 'transformSkew'));
    } else if (elemId.style.transformSkew === 'scale(-1)') {
      dispatch(elementUpdated(elemId, 'scaleY(-1)', 'transformSkew'));
    } else {
      dispatch(elementUpdated(elemId, 'scaleX(-1)', 'transformSkew'));
    }
  };
  const onChangefliptop = ({ event, props }) => {
    if (elemId.style.transformSkew === 'scaleY(-1)') {
      dispatch(elementUpdated(elemId, '', 'transformSkew'));
    } else if (elemId.style.transformSkew === 'scaleX(-1)') {
      dispatch(elementUpdated(elemId, 'scale(-1)', 'transformSkew'));
    } else if (elemId.style.transformSkew === 'scale(-1)') {
      dispatch(elementUpdated(elemId, 'scaleX(-1)', 'transformSkew'));
    } else {
      dispatch(elementUpdated(elemId, 'scaleY(-1)', 'transformSkew'));
    }
  };
  return (
    <Popover2
      content={
        <div className="rotatepop" style={{position:'absolute'}}>
          <Row style={{ marginTop: '-6px' }}>
            <Button
              className="PropertiesInput"
              asyncControl={true}
              name="vertical-align"
              value={0}
              minimal={true}
              onClick={handleClockWise}>
              <Clock90Icon />
              <Label className="buttonlabeltextcss">Clockwise 90</Label>
            </Button>
          </Row>
          <hr className="line_css" />
          <Row style={{ marginTop: '-6px' }}>
            <Button
              className="PropertiesInput"
              asyncControl={true}
              name="vertical-align"
              value={0}
              minimal={true}
              onClick={handleCounterClockWise}>
              <AnticlockIcontwo />
              <Label className="buttonlabeltextcss">counter clockwise 90</Label>
            </Button>
          </Row>
          <hr className="line_css" />
          <Row style={{ marginTop: '-6px' }}>
            <Button
              className="PropertiesInput"
              minimal={true}
              name="transform"
              value={flipright}
              style={{ border: '0px' }}
              onClick={onChangefliptop}>
              <FlipVertical />
              <Label className="buttonlabeltextcss">Flip Vertically</Label>
            </Button>
          </Row>
          <hr className="line_css" />
          <Row style={{ marginTop: '-6px' }}>
            <Button
              className="PropertiesInput"
              minimal={true}
              name="transform"
              value={fliptop}
              style={{ border: '0px' }}
              onClick={onChangeflipright}>
              <FlipHorizontal />
              <Label className="buttonlabeltextcss"> Flip Horizontally</Label>
            </Button>
          </Row>
        </div>
      }>
      <Button className="animaionaniatecss"  style={{marginTop:'20px'}} minimal={true}>
        <img src={rotate1} alt="rotate1" />
      </Button>
    </Popover2>
  );
};

export default Imagerotate;
