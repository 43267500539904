import React from 'react';
import { SketchPicker } from 'react-color';
import { useDispatch } from 'react-redux';
import { slideUpdated } from '../redux/actions';

const CustomSlideColor = ({ slideId, bgcolor }) => {

  const dispatch = useDispatch();
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const [colorPicker, setColorPicker] = React.useState({
    r: '241',
    g: '112',
    b: '19',
    a: '1',
  });

  const styles = {
    color: {
      width: '20px',
      height: '14px',
      borderRadius: '2px',
      background: bgcolor,
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
      marginLeft: '5px',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
      bottom: '150px'
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  };
  const colorChangeComplete = (color) => {
    if (slideId.id) {
      dispatch(slideUpdated(slideId, color.hex, 'background-color'));
      console.log('RED SLIDES', slideId, color.hex, 'backgroundColor');

    }
    console.log('colorChangeComplete', color);
  };
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setColorPicker(color.rgb);
  };
  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={colorPicker} onChange={handleChange} onChangeComplete={colorChangeComplete} />
        </div>
      ) : null}
    </div>
  );
};

export default CustomSlideColor;
