import React, { useCallback, useRef } from 'react';
import { Rnd } from '../../../ui/Rnd/Rnd';
import { MultilineEdit } from '../../../components/studio/MultiLineEdit';
import { Svg } from '../../../ui/Svg/Svg';
import { Image } from '../../../ui/Image/Image';
import { Iframe } from '../../../ui/Iframe/Iframe';
import { useDispatch, useSelector } from 'react-redux';
import { STUDIO_ELEMENTS } from '../../utils';
import { ELEMENT_Type } from '../../../constants';
import { drawerBehaviour, elementChanged, elementUpdated } from '../../../redux/actions';

import { useContextMenu } from 'react-contexify';

const { CANVAS_EL, CANVAS_FRAME_EL } = STUDIO_ELEMENTS;


const MENU_ID = 'menu-id';
const styleRnd = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const { DIV, IMG, SVG, TEXT, INDIV, ALL, POS, RND } = ELEMENT_Type;

const CanvasElements = ({ el, index, style, setStyle, aspectRatio }) => {
    const dispatch = useDispatch();
    const { selectedElement, selectedSlide, project } = useSelector((state) => state.Studio);


    const { show } = useContextMenu({
        id: MENU_ID,
        props: {
            key: '',
        },
    });

    const getRotateValueFromString = (elemIdRnd) => {
        return elemIdRnd.style.transformRotate !== '' && elemIdRnd.style.transformRotate !== undefined
            ? elemIdRnd.style.transformRotate.split('(')[1].split('d')[0]
            : '0';
    };

    const handleContextMenu = (event, element) => {
        event.preventDefault();
        show({
            event,
            props: {
                key: element,
                element: element
            }
        });
        // show(event);
    };
    const elemDragStop = (e, d, elem) => {
        if (!elem.position) {
            return;
        }
        let el = $(CANVAS_EL);

        let elpos = {
            top: (d.y * 100) / el.height(),
            left: (d.x * 100) / el.width(),
        };

        elem.position.x = d.x;
        elem.position.y = d.y;
        elem.position.top = elpos.top;
        elem.position.left = elpos.left;
        dispatch(elementChanged(elem));
    };
    const resizeElements = (ref, el, position, direction, delta, e) => {
        let ele = $(CANVAS_EL);
        let elpos = {
            top: (position.y * 100) / ele.height(),
            left: (position.x * 100) / ele.width(),
        };
        el.position.width = ref.style.width;
        el.position.height = ref.style.height;
        el.position.top = elpos.top;
        el.position.left = elpos.left;
        dispatch(elementChanged(el));

    };

    const getRNDPOS = useCallback((el) => {
        if (style?.width && style?.height) {
            let x = (el.position.left * parseInt(style.width.replace('px', ''))) / 100;
            let y = (el.position.top * parseInt(style?.height.replace('px', ''))) / 100;
            return { x: x, y: y };
        } else {
            return {
                x: 0,
                y: 0

            };
        }
    }, [style]);

    const getElemStyle = (el, index, type = 'all', isRotate = true) => {
        if (el.style['transformSkew'] === undefined) {
            el.style['transformSkew'] = '';
        }
        if (!el.style['transformRotate']) {
            el.style['transformRotate'] = '';
        }
        let style = getRecaliberatedStyleObject({ el, index, isRotate });
        if (el.type === SVG) {
            style = {
                ...style,
                stroke: el.style['stroke'] ? el.style['stroke'] : '#000',
                fill: el.style['fill'] ? el.style['fill'] : '#000',
                strokeWidth: el.style['strokeWidth'] ? el.style['strokeWidth'] : 0.1,
            };
        }
        if (el.type === TEXT) {
            style = {
                ...style,
                padding: '4px 8px',
                overflowWrap: 'break-word',
                overflow: 'hidden',
            };
        }

        return getStyleAccordingToType({ el, index, style, type });
    };

    const handleMouseDown = (e, el) => {
        // Check if the click is not on a resize handle
        dispatch(elementChanged(el));
        if (e.target.className.includes('edit-textarea')) {
            dispatch(elementUpdated(el, true, 'isDraggingDisabled'));
            return;
        }
        if (!e.target.className.includes('resize-handle')) {
            dispatch(elementUpdated(el, false, 'isDraggingDisabled'));
        }
    };

    const commonRNDProps = (type) => {
        return {
            rotate: getRotateValueFromString(el),
            key: el.id,
            className: el.id === selectedElement.id ? 'dragResizingActive' : '',
            onContextMenu: (e) => handleContextMenu(e, el),
            // onKeyDown: (e) => handleFocus(e, el),
            onDragStop: (e, d) => {
                elemDragStop(e, d, el);
            },
            onResizeStop: (e, direction, ref, delta, position) => {
                resizeElements(ref, el, position, direction, delta, e);
            },
            onResizeStart: (e, direction, ref, delta, position) => {
                // resizeElements(ref, el, position, direction, delta, e);
            },
            size: {
                width: parseFloat(el.position.width) + '%',
                height: parseFloat(el.position.height) + '%',
            },
            position: getRNDPOS(el),
            bounds: CANVAS_EL,
            style: getElemStyle(el, index, type),
            resizeHandleClasses: {
                topRight: 'resize-handle-top-right',
                bottomRight: 'resize-handle-bottom-right',
                bottomLeft: 'resize-handle-bottom-left',
                topLeft: 'resize-handle-top-left',
                top: 'resize-handle-top',
                right: 'resize-handle-right',
                bottom: 'resize-handle-bottom',
                left: 'resize-handle-left',
            }
        };
    };





    switch (el.type) {
        case TEXT:
            return (
                <Rnd key={el.id} {...commonRNDProps(POS)} id="textEdit" disableDragging={el?.isDraggingDisabled}

                    onMouseDown={(e) => handleMouseDown(e, el)}
                >
                    <MultilineEdit
                        elementObj={el}
                        value={el.content.text}
                        style={{ ...getElemStyle(el, index, 'indiv', false), pointerEvents: 'auto', height: '100%' }}
                    />
                </Rnd>
                // <div onKeyDown={handleFocus} tabIndex={0} style={{ ...getElemStyle(el, index, 'indiv', false), pointerEvents: 'auto' }}>Hello world</div>
            );
        case SVG:
            return (

                <Rnd {...commonRNDProps(RND)}>
                    <Svg
                        id={el.id}
                        onClick={() => dispatch(elementChanged(el))}
                        style={getElemStyle(el, index, 'indiv', false)}
                        {...el['attributes']}>
                        {el.childrens.map((item, index) => {
                            const fillRule = item['fill-rule'];
                            delete item['fill-rule'];
                            return <path key={index} fillRule={fillRule} {...item}></path>;
                        })}
                    </Svg>
                </Rnd>
            );
        case IMG:
            if (el.content.src) {
                return (
                    <Rnd {...commonRNDProps(POS)} disableDragging={el.disable} lockAspectRatio={aspectRatio}>
                        <Image
                            alt={el.name}
                            onClick={() => dispatch(elementChanged(el, 'img'))}
                            id={el.id}
                            style={getElemStyle(el, index, 'indiv', false)}
                            src={el.content?.src}
                        />
                    </Rnd>
                );
            } else {
                const { x, y } = getRNDPOS(el);
                return (
                    <div style={{
                        ...getElemStyle(el, index, 'all'),
                        transform: `translate(${x}px, ${y}px)`,
                        width: parseFloat(el.position.width) + '%',
                        height: parseFloat(el.position.height) + '%',
                        position: 'absolute',
                        top: 0,
                        left: 0
                    }}
                        onClick={() => dispatch(drawerBehaviour('image', true, true, el))}
                        onContextMenu={(e) => handleContextMenu(e, el)}
                    >
                        <Image
                            alt={el.name}
                            onClick={() => dispatch(elementChanged(el, 'img'))}
                            id={el.id}
                            style={getElemStyle(el, index, 'indiv', false)}
                            src={el.content.placeholder}
                        />
                    </div>
                );
            }


        case DIV:
            //this is for embeds iframe
            return (
                <Rnd {...commonRNDProps(POS)}>
                    <div className="iframe-wrapper" id={el.id} onClick={() => dispatch(elementChanged(el))}>
                        <Iframe
                            title="iframe"
                            className={el.id === selectedElement.id ? 'dragResizingActive' : ''}
                            {...el.attributes}
                        />
                    </div>
                </Rnd>
            );

        default:
            return null;
    }
};

export default CanvasElements;


function getRecaliberatedStyleObject({ el, index, isRotate = true }) {
    return {
        position: 'absolute',
        opacity: el.style['opacity'] ? el.style['opacity'] + '0' : '0.1',
        pointerEvents: el.style['pointer-events'] ? el.style['pointer-events'] : 'stroke',
        backgroundColor: el.style['backgroundColor'] ? el.style['backgroundColor'] : 'rgba(255,255,255,0)',
        borderWidth: el.style['border-width'] ? el.style['border-width'] + 'px' : '0',
        borderStyle: el.style['border-style'] ? el.style['border-style'] : 'solid',
        display: el.style['display'] ? el.style['display'] : 'block',
        borderColor: el.style['border-color'] ? el.style['border-color'] : '#fff',
        borderRadius: el.style['border-radius'] ? el.style['border-radius'] + 'px' : '0',
        color: el.style['color'] ? el.style['color'] : '#000',
        fontFamily: el.style['font-family'] ? el.style['font-family'] : '',
        fontSize: el.style['font-size'] ? calcFontSize(el) + 'px' : '55px',
        fontWeight: el.style['font-weight'] ? el.style['font-weight'] : '',
        textAlign: el.style['text-align'] ? el.style['text-align'] : 'center',
        zIndex: el.style['z-index'] ? el.style['z-index'] : index,
        fontStyle: el.style['font-style'] ? el.style['font-style'] : 'normal',
        textDecoration: el.style['text-decoration'] ? el.style['text-decoration'] : '',
        transform: el.style['transformRotate'] + ' ' + el.style['transformSkew'],
        textTransform: el.style['text-transform'] ? el.style['text-transform'] : '',
        verticalAlign: el.style['vertical1'] ? el.style['vertical1'] : '',
    };
}

function calcFontSize(el) {
    let size = 0;
    let constant = 600;
    let lcanvas = $(CANVAS_EL);
    if (el.textstyle) {
        size = el.textstyle['font-size'];
    } else {
        size = el.style['font-size'];
    }

    if (!size) {
        return false;
    }

    let fs;
    let sum;
    let con;
    sum = lcanvas.width();
    let per = (sum / constant) * 100;
    fs = (per / 100) * size;
    if (per < 100) {
        fs -= parseInt(100 - per + 1) * 0.015;
    }
    return fs;
}

function getStyleAccordingToType({ el, index, style, type = ALL }) {
    switch (type) {
        case ALL:
            return {
                ...style,
                top: el.position.top + '%',
                left: el.position.left + '%',
                width: el.position.width + '%',
                height: el.position.height + '%',
            };
        case INDIV:
            return {
                ...style,
                top: '0%',
                left: '0%',
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
            };

        case POS:
            return {
                ...styleRnd,
                transform: el.style['transformRotate'] + el.style['transformSkew'],
                zIndex: el.style['z-index'] ? el.style['z-index'] : index,
            };
        case RND:
            return {
                ...styleRnd,
                transform: el.style['transformRotate'] + el.style['transformSkew'],
                zIndex: el.style['z-index'] ? el.style['z-index'] : index,
                x: el.position.x,
                y: el.position.y,
            };
        case TEXT:
            return {
                ...style,
                pointerEvents: null,
            };
    }
}
