import React from 'react';
import { Button } from '@blueprintjs/core';
import { useDispatch } from 'react-redux';
import { elementadd } from '../../../redux/studio/actions';
import { Form, Input } from 'antd';
import QueryString from 'qs';

function AddVideo( props ) {
  const dispatch = useDispatch();
   
  const onFinish = (e) => {
    let src = '';

    if (e.embedCode.includes('youtu.be')) {
      let split = e.embedCode.split('/');
      src = 'https://www.youtube.com/embed/' + split[split.length - 1];
    }
    else if (e.embedCode.includes('www.youtubekids.com/watch')) {
      let str = e.embedCode.split('?')[1];
      let parsedObject = QueryString.parse(str);

      src = parsedObject.list
                ? `https://www.youtube.com/embed/${parsedObject.v}?list=${parsedObject.list}`
                : `https://www.youtube.com/embed/${parsedObject.v}`;
    }
    else if (e.embedCode.includes('www.youtube.com/watch')) {
      let str = e.embedCode.split('?')[1];
      let parsedObject = QueryString.parse(str);

      src = parsedObject.list
                ? `https://www.youtube.com/embed/${parsedObject.v}?list=${parsedObject.list}`
                : `https://www.youtube.com/embed/${parsedObject.v}`;
    }
    if (src) {
      e.embedCode = `<iframe width="1280" height="720" src="${src}" title="" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen></iframe>`;
    }
    var dom = document.createElement('div');
    dom.innerHTML = e.embedCode;
    let child = dom.children[0];
    let attr = child.attributes;
    let attributes = {};
    //dynamically adding the props of svg to the attributes object
    Object.keys(attr).forEach((key) => {
      if (typeof key === 'string') {
        let val = attr[key];
        //basic dom operation to get nodeValue
        attributes[`${val.name}`] = val.nodeValue;
      }
    });
    attributes['width'] = '100%';
    attributes['height'] = '100%';
    dispatch(
            elementadd({
              value: { code: e.embedCode, attributes },
              type: 'embed',

            })
    );
    props.handleClose();

  };

  return (
    <div style={{ width: '284px', display: 'flex', justifyContent: 'center' }}>
      <Form onFinish={onFinish}>
        <Form.Item name="embedCode">
          <Input.TextArea
            placeholder="Insert Iframe of Youtube video or Map"
            style={{ width: '282px', height: '142px' }}
          />
        </Form.Item>
        <button
          className="secondary-button"
          style={{ marginTop: '10px' }}
          type="submit">
                    Ok
        </button>
      </Form>
    </div>
  );
}

export default AddVideo;
