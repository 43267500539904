import React, { useState, useCallback } from 'react';
import { Card } from './Card';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { elementChanged } from '../../redux/studio/actions';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from '../../helpers/usePrevious';
import { elementIndexChanged } from '../../redux/studio/actions';

function Element() {
  const dispatch = useDispatch();
  const { selectedSlide } = useSelector((state) => state.Studio);

  const [slideName, setslidename] = useState('');
  const [cards, setCards] = useState([]);
  const previous = usePrevious(cards);
  React.useEffect(() => {
    if (selectedSlide?.id) {
      setCards(selectedSlide?.elements);
      setslidename(selectedSlide?.Meta.Name);
    }
  }, [selectedSlide.elements]);
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);
  React.useEffect(() => {
    if (previous && previous.length) {
      dispatch(elementIndexChanged(cards));
    }
  }, [cards]);

  const renderCard = useCallback((card, index) => {
    return (
      <div
        key={index}
        onClick={() => {
          dispatch(elementChanged(card));
        }}
        className="elements">
        <Card
          index={index}
          text={card.name}
          icon={card.icon}
          disable={card.disable}
          elementId={card.id}
          moveCard={moveCard}
          className="ElementCard"
        />
      </div>
    );
  });
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div style={{ padding: '22px', marginTop: '-20px', height: 'calc(100% - 15px)' }}>
          <div className="elementsectionone">
            <div
              style={{
                flexWrap: 'wrap',
                width: '258px',
              }}>
              {cards.map((card, i) => renderCard(card, i))}
            </div>
          </div>
        </div>
      </DndProvider>
    </>
  );
}
export default Element;
