import React, { useState } from 'react';
import { Button, Position, Tooltip, Icon, Classes } from '@blueprintjs/core';
import Element from '../Element';
import { Drawer } from '../../../ui/Drawer/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { SlidenameIcon } from '../../../ProjectSVG';

// TODO: decouple

export const ElementsDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(() => false);
  const { selectedSlide } = useSelector((state) => state.Studio);

  const [slideName, setslidename] = useState('');
  React.useEffect(() => {
    if (selectedSlide?.id) {
      setslidename(selectedSlide?.Meta.Name);
    }
  }, [selectedSlide?.elements]);
  return (
    <div>
      <Tooltip content="Elements" position={Position.RIGHT_TOP}>
        <Button onClick={() => setIsDrawerOpen(true)} minimal={true}>
          <Icon icon={'layers'} size={18} color={'#737475'} />
        </Button>
      </Tooltip>
      <Drawer
        className="drawer"
        position={Position.LEFT}
        onClose={() => setIsDrawerOpen(false)}
        isOpen={isDrawerOpen}
        title="Layers">
        <div style={{ display: 'flex', marginLeft: '13px', marginTop: '10px' }}>
          <span>
            <SlidenameIcon />
          </span>
          <p className="slideName">{slideName}</p>
        </div>
        <hr />
        <div className={Classes.DRAWER_BODY}>
          <Element />
        </div>
      </Drawer>
    </div>
  );
};
