import React, { Component } from 'react';
import { MenuItem, Menu, Dialog, Classes, Alignment, Navbar, NavbarGroup } from '@blueprintjs/core';
import AddShapes from './AddShapes';
import StudioToolbar1 from './StudiotoolbartopLeft';
import StudioToolbar2 from './StudiotoolbartopRignt';
import ToolbarDrawer from './ToolbarDrawer';

class StudioToolbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showsModel: false,
            alignRight: false,
        };
    }

    fontStyling = (
        <Menu>
            <MenuItem icon="graph" text="Style1" />
            <MenuItem icon="map" text="Style2" />
        </Menu>
    );

    render() {
        // console.log('my props', this.props.style?.elements['font-size']);
        return (
            <React.Fragment>
                <Navbar style={{ height: "auto" }}>
                    <NavbarGroup align={Alignment.LEFT}>
                        <ToolbarDrawer deleteElementText={() => { }} />
                        <StudioToolbar1 aspectHandler={this.props.aspectHandler} isAspectOn={this.props.isAspectOn} />
                    </NavbarGroup>
                    <NavbarGroup align={Alignment.RIGHT}>
                        <StudioToolbar2 />
                    </NavbarGroup>
                </Navbar>
                <Dialog
                    className="shapesOverlay"
                    enforceFocus={false}
                    onClose={() => {
                        this.setState({ showsModel: false });
                    }}
                    isOpen={this.state.showsModel}
                    autoFocus={true}
                    canEscapeKeyClose={true}
                    canOutsideClickClose={true}
                    usePortal={false}>
                    <div className={Classes.DIALOG_BODY}>
                        <AddShapes />
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return { style: state.Studio.headerStyle };
};

export default StudioToolbar;
