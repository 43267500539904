import { Row, Col } from 'antd';
import { elementadd } from '../../../redux/studio/actions';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { Button, Classes, Position, Label, Tooltip } from '@blueprintjs/core';

import { Drawer } from '../../../ui/Drawer/Drawer';
import {
  Papers,
  Shapes,
  ArrowDownWithShade,
  ArrowLeftWithCircle,
  ArrowUpWithCircle,
  ArrowDownWithCircle,
  Refresh,
  Triangle,
  Pentagon,
  Circle,
  PentagonSolid,
  CircleSolid,
  TriangleSolid,
  TriangleCircleSolid,
  TriangleCircleSolidFlipped,
  ArrowUp,
  ArrowDown,
  ArrowRight,
  ArrowLeft,
  ArrowSolidDown,
  ArrowSolidLeft,
  ArrowSolidRight,
  ArrowSolidUp,
  CrossHair,
  Stack,
  StackHorizontal,
  PapersSolid,
  StackSolid,
  TextAlignRight,
  TextAlignCenter,
  TextAlignLeft,
  Paragraph,
  CheckList,
  ChevronRight,
  ChevronLeft,
  ChevronDown,
  X,
  WindowsCursor,
} from '../../../ui/Icons';

// KEEP IN MIND WHENEVER U ADD A SVG HERE
// ITS PARENT SHOULD HAVE A ID AND ONCLICK FUNCTION
// TODO: decouple and extract the svgs
export const AddShapesDrawer = () => {
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(() => false);
  const [clicked, setClicked] = useState(0);
  const [show, setShow] = useState(false);
  const [newshow, setNewshow] = useState(false);
  const svgClicked = (e) => {
    //getting the svg whose id is saved in Clicked state
    e = document.getElementById(`${clicked}`).children[0];
    //getting the attributes/properties
    let eventAttributes = e.attributes;
    let attributes = {};
    //dynamically adding the props of svg to the attributes object
    Object.keys(eventAttributes).forEach((key) => {
      if (typeof key === 'string') {
        let val = eventAttributes[key];
        //basic dom operation to get nodeValue
        attributes[`${val.name}`] = val.nodeValue;
      }
    });
    //getting the childrens of the svg ie path and getting the props of that path
    let eventChildren = e.children;
    let childrens = [...eventChildren].map((item) => {
      let obj = {};
      Object.keys(item.attributes).forEach((key) => {
        if (typeof key === 'string') {
          let val = item.attributes[key];
          obj[`${val.name}`] = val.nodeValue;
        }
      });
      return obj;
    });
    // let childrens = [...eventChildren].map((item) => ({
    //     d: item.attributes['d'].nodeValue,
    //     fill: item.attributes['fill'].nodeValue,
    // }));
    dispatch(
            elementadd({
              type: 'svg',
              value: { attributes, childrens },
            })
    );
    setIsDrawerOpen(false);
  };
  React.useEffect(() => {}, [clicked]);

  return (
    <div>
      {/* <Button onClick={handleOpen} icon="insert" /> */}
      <Button minimal={true} onClick={() => setIsDrawerOpen(true)} className="drawerbuttons">
        <div>
          <Tooltip content="Add Shapes" position={Position.RIGHT_TOP}>
            <Shapes />
          </Tooltip>
        </div>
      </Button>
      <Drawer
        className="drawer"
        position={Position.LEFT}
        onClose={() => setIsDrawerOpen(false)}
        isOpen={isDrawerOpen}
        title="Shapes">
        <div className={Classes.DRAWER_BODY} onClick={(e) => setClicked(0)}>
          <div style={{ overflow: 'hidden', padding: 8 }}>
            <div className="addshapeparacss">
              <p>Basic Shapes</p>
              {show ? (
                                <Button
                                  minimal={true}
                                  onClick={(e) => {
                                    setShow(false);
                                  }}
                                  className="addshapeshowbutton">
                                    - See Less
                                </Button>
                            ) : (
                                <Button
                                  minimal={true}
                                  onClick={(e) => {
                                    setShow(true);
                                  }}
                                  className="addshapeshowbutton">
                                    + See All
                                </Button>
                            )}
            </div>

            <div className="shapedivcss">
              <div
                id="1"
                className={clicked === 1 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(1);
                }}>
                <ArrowDownWithShade />
              </div>
              <div
                id="2"
                className={clicked === 2 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(2);
                }}>
                <ArrowLeftWithCircle />
              </div>
              <div
                id="3"
                className={clicked === 3 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(3);
                }}>
                <ArrowDownWithCircle />
              </div>
              <div
                id="4"
                className={clicked === 4 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(4);
                }}>
                <ArrowUpWithCircle />
              </div>
              <div
                id="5"
                className={clicked === 5 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(5);
                }}>
                <Refresh />
              </div>
              <div
                id="6"
                className={clicked === 6 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(6);
                }}>
                <Triangle />
              </div>
              <div
                id="7"
                className={clicked === 7 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(7);
                }}>
                <Triangle />
              </div>
              <div
                id="8"
                className={clicked === 8 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(8);
                }}>
                <Pentagon />
              </div>
              <div
                id="9"
                className={clicked === 9 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(9);
                }}>
                <Circle />
              </div>
              <div
                id="10"
                className={clicked === 10 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(10);
                }}>
                <Pentagon />
              </div>
              <div
                id="11"
                className={clicked === 11 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(11);
                }}>
                <CircleSolid />
              </div>
              <div
                id="12"
                className={clicked === 12 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(12);
                }}>
                <PentagonSolid />
              </div>
              <div
                id="13"
                className={clicked === 13 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(13);
                }}>
                <TriangleSolid />
              </div>
              <div
                id="14"
                className={clicked === 14 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(14);
                }}>
                <TriangleCircleSolid />
              </div>
              <div
                id="15"
                className={clicked === 15 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(15);
                }}>
                <TriangleCircleSolidFlipped />
              </div>
              {show && (
                <>
                  <div
                    id="16"
                    className={
                                            clicked === 16 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setClicked(16);
                    }}>
                    <ArrowRight />
                  </div>
                  <div
                    id="17"
                    className={
                                            clicked === 17 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setClicked(17);
                    }}>
                    <ArrowUp />
                  </div>
                  <div
                    id="18"
                    className={
                                            clicked === 18 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setClicked(18);
                    }}>
                    <ArrowDown />
                  </div>
                  <div
                    id="19"
                    className={
                                            clicked === 19 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setClicked(19);
                    }}>
                    <ArrowLeft />
                  </div>
                  <div
                    id="20"
                    className={
                                            clicked === 20 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setClicked(20);
                    }}>
                    <Refresh />
                  </div>
                </>
              )}
            </div>
            <hr className="addshapehr" />
            <div className="addshapeparacss">
              <p>Lines</p>
              {newshow ? (
                                <Button
                                  minimal={true}
                                  onClick={(e) => {
                                    setNewshow(false);
                                  }}
                                  className="addshapeshowbutton">
                                    - See Less
                                </Button>
                            ) : (
                                <Button
                                  minimal={true}
                                  onClick={(e) => {
                                    setNewshow(true);
                                  }}
                                  className="addshapeshowbutton">
                                    + See All
                                </Button>
                            )}
            </div>

            <div className="shapedivcss">
              <div
                id="21"
                className={clicked === 21 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(21);
                }}>
                <ArrowSolidRight />
              </div>
              <div
                id="22"
                className={clicked === 22 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(22);
                }}>
                <ArrowSolidLeft />
              </div>
              <div
                id="23"
                className={clicked === 23 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(23);
                }}>
                <ArrowSolidDown />
              </div>
              <div
                id="24"
                className={clicked === 24 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(24);
                }}>
                <ArrowSolidUp />
              </div>
              <div
                id="25"
                className={clicked === 25 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(25);
                }}>
                <CrossHair />
              </div>

              <div
                id="26"
                className={clicked === 26 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(26);
                }}>
                <Stack />
              </div>
              <div
                id="27"
                className={clicked === 27 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(27);
                }}>
                <StackHorizontal />
              </div>
              <div
                id="28"
                className={clicked === 28 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(28);
                }}>
                <Papers />
              </div>
              <div
                id="29"
                className={clicked === 29 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(29);
                }}>
                <PapersSolid />
              </div>
              <div
                id="30"
                className={clicked === 30 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(30);
                }}>
                <StackSolid />
              </div>

              <div
                id="31"
                className={clicked === 31 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(31);
                }}>
                <TextAlignRight />
              </div>
              <div
                id="32"
                className={clicked === 32 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(32);
                }}>
                <TextAlignCenter />
              </div>
              <div
                id="33"
                className={clicked === 33 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(33);
                }}>
                <TextAlignLeft />
              </div>
              <div
                id="34"
                className={clicked === 34 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(34);
                }}>
                <Paragraph />
              </div>
              <div
                id="35"
                className={clicked === 35 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'}
                onClick={(e) => {
                  e.stopPropagation();
                  setClicked(35);
                }}>
                <CheckList />
              </div>
              {newshow && (
                <>
                  <div
                    id="36"
                    className={
                                            clicked === 36 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setClicked(36);
                    }}>
                    <ChevronRight />
                  </div>
                  <div
                    id="37"
                    className={
                                            clicked === 37 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setClicked(37);
                    }}>
                    <ChevronLeft />
                  </div>
                  <div
                    id="38"
                    className={
                                            clicked === 38 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setClicked(38);
                    }}>
                    <ChevronDown />
                  </div>
                  <div
                    id="39"
                    className={
                                            clicked === 39 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setClicked(39);
                    }}>
                    <X />
                  </div>
                  <div
                    id="40"
                    className={
                                            clicked === 40 ? 'dragResizingActive  spacebrwIcons' : 'spacebrwIcons'
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setClicked(40);
                    }}>
                    <WindowsCursor />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={Classes.DRAWER_FOOTER}>
          <Row style={{ display: 'flex', justifyContent: 'right' }}>
            <Col>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <p>{clicked > 0 ? 1 : 0} Selected</p>{' '}
                <Button
                  className="drawerinsertButton"
                  onClick={() => {
                    clicked > 0 && svgClicked(null);
                  }}>
                                    Insert
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Drawer>
    </div>
  );
};
