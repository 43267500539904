import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { projectInit } from '../redux/studio/actions';
import appConfig from '../config';
import { Row, Col } from 'reactstrap';
import { Button } from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import StudioToolbar from './components/StudioToolbar';
import StudioToolbarLeft from './components/studioToolbarLeft';
import CanvasFrame from './components/canvasFrame';
import '@blueprintjs/core/lib/css/blueprint.css';
import Save from './components/StudioTop/Save';
import Slides from './components/slides';
import Publish from '../studio/components/StudioTop/Publish';
import Detailoverlay from './components/Detailoverlay';
import Projectproperty from './components/Projectproperty';
import Preview from '../studio/components/StudioTop/Preview';
import { Slider } from 'antd';
import $ from 'jquery';
import { ExpandIcon, QuestionIcon, ShrinkIcon } from '../ProjectSVG';
import ProjectTitle from './components/ProjectTitle';
import GridIcon from '../components/GridIcon';
import GridIconOff from '../components/GridIconOff';
import AccountDetail from '../components/AccountDetail';
import Topbar from '../components/Topbar';
import {
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';

function Studio(props) {
  const [state, setState] = useState({
    id: 'bisma',
    font: undefined,
    fullScreen: false,
    isGrid: false,
    isAspectOn: true,
    projectType: 'studio',
    sliderValue: 1
  });
  let location = useLocation();
  let params = useParams();
  const gridRef = useRef();
  const [style, setStyle] = useState({
    height: '100%',
    width: '100%',
  });

  const checkFullScreen = () => {
    let elem = document.documentElement;
    if (state.fullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    } else {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
    setState((prevState) => ({ ...prevState, fullScreen: !prevState.fullScreen }));
  };

  useEffect(() => {
    if (props.project) {
      let width = props.project.meta?.size?.width;
      console.log('width::', width);
      setStyle(prev => ({
        ...prev,
        sliderValue: prev.width / width
      }));
    }
  }, [props.project]);

  const onChange = (value) => {
    let canvas = $('#lcanvas');
    let { height, width } = props.project.meta.size;
    canvas.css('width', `${width * value}px`);
    canvas.css('height', `${height * value}px`);
  };

  const onAfterChange = (value) => {
    console.log('onAfterChange: ', value);
  };

  const addGrid = () => {
    setState((prevState) => ({ ...prevState, isGrid: !prevState.isGrid }));
  };

  const aspectHandler = (value) => {
    setState((prevState) => ({ ...prevState, isAspectOn: !value }));
  };

  useEffect(() => {
    let projectType = '';
    if (location.pathname.includes('theme')) {
      projectType = 'theme';
    }
    let type = location.search.split('=')[1];
    props.projectInit(params?.cid, location.state?.type || type || projectType);

    return () => {
      props.projectInit(null);
    };
  }, [location.pathname, location.search, location.state, params?.cid]);

  const exampleMenu = (
    <div className="FileDetail">
      <Detailoverlay />
    </div>
  );

  useEffect(() => {
    window.studioState = state;
    window.studioProps = props;
    if (gridRef.current) {
      if (state.isGrid) {
        gridRef.current.style.backgroundImage = 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc2MCcgaGVpZ2h0PSc2MCc+PGxpbmUgeDE9JzAnIHkxPScwJyB4Mj0nMCcgeTI9JzYwJyBzdHlsZT0nc3Ryb2tlOiNkNzE0MTQ7c3Ryb2tlLXdpZHRoOjI7c3Ryb2tlLWRhc2hhcnJheT0iMCInIC8+PGxpbmUgeDE9JzAnIHkxPScwJyB4Mj0nNjAnIHkyPScwJyBzdHlsZT0nc3Ryb2tlOiNkNzE0MTQ7c3Ryb2tlLXdpZHRoOjI7c3Ryb2tlLWRhc2hhcnJheT0iMCInIC8+PGxpbmUgeDE9JzEyJyB5MT0nMCcgeDI9JzEyJyB5Mj0nNjAnIHN0eWxlPSdzdHJva2U6I2RkZDtzdHJva2Utd2lkdGg6MTtzdHJva2UtZGFzaGFycmF5PSIwIicgLz48bGluZSB4MT0nMjQnIHkxPScwJyB4Mj0nMjQnIHkyPSc2MCcgc3R5bGU9J3N0cm9rZTojZGRkO3N0cm9rZS13aWR0aDoxO3N0cm9rZS1kYXNoYXJyYXk9IjAiJyAvPjxsaW5lIHgxPSczNicgeTE9JzAnIHgyPSczNicgeTI9JzYwJyBzdHlsZT0nc3Ryb2tlOiNkZGQ7c3Ryb2tlLXdpZHRoOjE7c3Ryb2tlLWRhc2hhcnJheT0iMCInIC8+PGxpbmUgeDE9JzQ4JyB5MT0nMCcgeDI9JzQ4JyB5Mj0nNjAnIHN0eWxlPSdzdHJva2U6I2RkZDtzdHJva2Utd2lkdGg6MTtzdHJva2UtZGFzaGFycmF5PSIwIicgLz48bGluZSB4MT0nMCcgeTE9JzEyJyB4Mj0nNjAnIHkyPScxMicgc3R5bGU9J3N0cm9rZTojZGRkO3N0cm9rZS13aWR0aDoxO3N0cm9rZS1kYXNoYXJyYXk9IjAiJyAvPjxsaW5lIHgxPScwJyB5MT0nMjQnIHgyPSc2MCcgeTI9JzI0JyBzdHlsZT0nc3Ryb2tlOiNkZGQ7c3Ryb2tlLXdpZHRoOjE7c3Ryb2tlLWRhc2hhcnJheT0iMCInIC8+PGxpbmUgeDE9JzAnIHkxPSczNicgeDI9JzYwJyB5Mj0nMzYnIHN0eWxlPSdzdHJva2U6I2RkZDtzdHJva2Utd2lkdGg6MTtzdHJva2UtZGFzaGFycmF5PSIwIicgLz48bGluZSB4MT0nMCcgeTE9JzQ4JyB4Mj0nNjAnIHkyPSc0OCcgc3R5bGU9J3N0cm9rZTojZGRkO3N0cm9rZS13aWR0aDoxO3N0cm9rZS1kYXNoYXJyYXk9IjAiJyAvPjwvc3ZnPg==")';
      } else {
        gridRef.current.style.backgroundImage = 'none';
      }
    }

  }, [state.isGrid, gridRef]);

  return (
    <div style={{ height: '100vh', width: '100vw', overflow: 'hidden', position: 'relative', zIndex: 10, display: 'flex', flexDirection: 'column' }}>
      <Topbar mainTopbar={false} />
      {props.project?.slides?.length ? (
        <Row
          style={{
            flexGrow: 1,
            width: '100%',
            borderBottom: '1px solid #d1d1d1',
            // height: '92vh',
          }}>
          <Col style={{ padding: '0px', display: 'flex', flexDirection: 'column' }}>
            <Row style={{ height: '100%', marginLeft: '12px', flexWrap: 'nowrap' }}>
              <Col
                style={{
                  borderRight: '1px solid #D1D1D1',
                  maxWidth: '56px',
                  background: 'white',
                }}>
                <StudioToolbarLeft />
              </Col>
              <Col
                style={{
                  backgroundColor: '#ffff',
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0px',
                }}>
                <Row
                  style={{
                    marginRight: '25px',
                    marginLeft: '0px',
                    width: '100%',
                  }}>
                  <Col
                    style={{
                      backgroundColor: 'white',
                      borderTop: '1px solid #D1D1D1',
                      borderBottom: '1px solid #D1D1D1',

                      margin: '0px',
                      height: '41px',
                      zIndex: 1000,
                      position: 'relative'
                    }}>
                    <StudioToolbar
                      aspectHandler={aspectHandler}
                      isAspectOn={state.isAspectOn}
                    />
                  </Col>
                </Row>
                <Row style={{ flexGrow: 1 }}>
                  <Col
                    style={{
                      flexGrow: 1,
                      position: 'relative'
                    }}>
                    <CanvasFrame isAspectOn={state.isAspectOn} gridRef={gridRef} style={style} setStyle={setStyle} />
                  </Col>
                </Row>
                <Row
                  style={{
                    // position: 'absolute',
                    // bottom: '68px',
                    height: '112px',
                    width: '100%',
                    backgroundColor: '#fff',
                  }}>
                  <Col style={{ backgroundColor: '#fff', padding: '1rem' }}>
                    <Slides />
                  </Col>
                </Row>
                <Row className="bottomToolbar" style={{ backgroundColor: '#fff' }}>
                  <Col style={{ display: 'flex', justifyContent: 'right' }}>
                    <div onClick={addGrid} className="grid-icon">
                      {state.isGrid ? <GridIconOff /> : <GridIcon />}
                    </div>
                    <span className="bottomSlider" style={{ width: '12%' }}>
                      <Slider
                        defaultValue={state.sliderValue}
                        min={1}
                        max={2}
                        step={0.1}
                        onChange={onChange}
                        onAfterChange={onAfterChange}
                      />
                    </span>
                    {/* <PagesIcon /> */}
                    <div onClick={checkFullScreen} style={{ marginTop: '6px' }}>
                      {state.fullScreen ? <ShrinkIcon /> : <ExpandIcon />}
                    </div>
                    <div style={{ marginTop: '6px' }}>
                      <QuestionIcon />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <div
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div className="studioloader" />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user, error } = state.Auth;
  const { loading, project, selectedElement, selectedSlide, slides, title, elements, projectid } = state.Studio;
  return {
    user,
    loading,
    error,
    project,
    projectid,
    selectedElement,
    selectedSlide,
    slides,
    title,
    elements,
  };
};

export default connect(mapStateToProps, { projectInit })(Studio);
