import React, { useEffect, useState } from 'react';
import { Select, Collapse, Divider } from 'antd';
// import 'antd/dist/antd.css';
import { DeleteOutlined } from '@ant-design/icons';
import appConfig from '../../config';
import { Button } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { elementUpdated } from '../../redux/studio/actions';

const { Option } = Select;
const { Panel } = Collapse;

function ElementAnimation() {
  const { selectedElement } = useSelector((state) => state.Studio);
  const dispatch = useDispatch();
  let elemId = selectedElement;
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    let animClasses = [...selectedElement.styleClasses];

    let index = animClasses.findIndex((item) => item === 'animate__animated');
    if (index === -1) {
      setClasses(animClasses);
    } else {
      animClasses.splice(index, 1);

      setClasses(animClasses);
    }
  }, [selectedElement]);

  function handleAddClass() {
    const callValue = document.getElementById('addClass').value;
    const typeValue = 'click';
    dispatch(elementUpdated(elemId, callValue, 'addclassCall'));
    dispatch(elementUpdated(elemId, typeValue, 'addclassType'));
  }

  function handleChange(event) {
    let animClasses = event;
    console.log('tyahi', event);
    if (event.length && event.indexOf('animate__animated') === -1) { animClasses = ['animate__animated', animClasses]; }
    setClasses([event]);
    dispatch(elementUpdated(elemId, animClasses, 'styleClasses'));
  }

  function handleDelete() {
    const event = [];
    dispatch(elementUpdated(elemId, event, 'styleClasses'));
    setClasses([event]);
  }
  return (
    <>
      <Collapse defaultActiveKey={['1']}>
        <Panel
          header={classes.length > 0 ? <p>{classes}</p> : <p>Animate</p>}
          key="1"
          extra={<DeleteButton handleDelete={handleDelete} />}>
          <Button
            onClick={handleAddClass}
            minimal={true}
            value={'addClass'}
            id="addClass"
            style={{ width: '100%', display: 'block' }}>
            <p style={{ fontSize: '12px', color: ' darkgray', marginBottom: '5px' }}>Choose the movement</p>
            <Select
              name="styleClasses"
              allowClear
              style={{ width: '100%' }}
              placeholder="Select Animation"
              value={classes}
              onChange={handleChange}>
              {getOptionsEl(appConfig.animationList)}
            </Select>
          </Button>
        </Panel>
      </Collapse>
      <Divider dashed />
    </>
  );
}

function getOptionsEl(options) {
  const children = [];
  for (let i = 0; i < options.length; i++) {
    children.push(<Option key={'animate__' + options[i]}>{options[i]}</Option>);
  }
  return children;
}

const DeleteButton = ({ handleDelete = () => { } }) => {
  return (
    <Button value={''} onClick={handleDelete} minimal={true}>
      <DeleteOutlined />
    </Button>
  );
};

export default ElementAnimation;
