import React, { useEffect, useState } from 'react';
import { Select, Input } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
// import 'antd/dist/antd.css';
import appConfig from '../../config';
import { H6, Button } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { elementUpdated } from '../../redux/studio/actions';
import { Collapse } from 'antd';

const { Panel } = Collapse;
const { Option } = Select;

function ElementsBehaviour() {
  const { selectedElement, project, selectedSlide } = useSelector((state) => state.Studio);
  let Project = project;
  let slideName = [];
  let slideId = [];
  for (let i = 0; i < Project.slides.length; i++) {
    slideName[i] = Project.slides[i].index;
    slideId[i] = Project.slides[i].id;
  }
  let elementsName = [];
  let elementsId = [];
  for (let i = 0; i < selectedSlide.elements.length; i++) {
    elementsName[i] = selectedSlide.elements[i].name;
    elementsId[i] = selectedSlide.elements[i].id;
  }

  const dispatch = useDispatch();

  const [showparams, setShowParams] = useState([]);
  const [switchparams, setSwitchParams] = useState([]);
  const [hideparams, setHideParams] = useState([]);
  const [linkparams, setLinkParams] = useState([]);

  const [classes, setClasses] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  function handleSwitchsld() {
    const callValue = document.getElementById('switchslide').value;
    const typeValue = 'click';
    dispatch(elementUpdated(elemId, callValue, 'switchCall'));
    dispatch(elementUpdated(elemId, typeValue, 'switchType'));
  }
  const { TextArea } = Input;
  function handleswitchsldparamChange(event) {
    dispatch(elementUpdated(elemId, event, 'switchParam'));
    setSwitchParams(event);
  }
  //Show
  function handleShow() {
    const callValue = document.getElementById('show').value;
    const typeValue = 'click';
    dispatch(elementUpdated(elemId, callValue, 'showCall'));
    dispatch(elementUpdated(elemId, typeValue, 'showType'));
  }
  function handleShowDelete() {
    const event = [];
    dispatch(elementUpdated(elemId, event, 'showParam'));
    setShowParams(event);
  }
  function handleSwitchDelete() {
    const event = [];
    dispatch(elementUpdated(elemId, event, 'switchParam'));
    setSwitchParams(event);
  }

  function handleHideDelete() {
    const event = [];
    dispatch(elementUpdated(elemId, event, 'hideParam'));
    setHideParams(event);
  }
  function handleLinkDelete() {
    const event = [];
    dispatch(elementUpdated(elemId, event, 'linkParam'));
    setLinkParams(event);
  }
  function handleshowparamChange(event) {
    dispatch(elementUpdated(elemId, event, 'showParam'));
    setShowParams(event);
  }
  function handleAddClass() {
    const callValue = document.getElementById('addClass').value;
    const typeValue = 'click';
    dispatch(elementUpdated(elemId, callValue, 'addclassCall'));
    dispatch(elementUpdated(elemId, typeValue, 'addclassType'));
  }

  function handleaddclassparamChange(event) {
    let aniamte = { event, classes };
    dispatch(elementUpdated(elemId, aniamte, 'addclassParam'));
  }

  //HIDE
  function handleHide() {
    const callValue = document.getElementById('hide').value;
    const typeValue = 'click';
    dispatch(elementUpdated(elemId, callValue, 'hideCall'));
    dispatch(elementUpdated(elemId, typeValue, 'hideType'));
  }

  function handlehideparamChange(event) {
    dispatch(elementUpdated(elemId, event, 'hideParam'));
    setHideParams(event);
  }

  function handleLink() {
    const callValue = document.getElementById('link').value;
    const typeValue = 'click';
    dispatch(elementUpdated(elemId, callValue, 'linkCall'));
    dispatch(elementUpdated(elemId, typeValue, 'linkType'));
  }
  function handleLinkparamChange(event) {
    dispatch(elementUpdated(elemId, event.target.value, 'linkParam'));
    setLinkUrl(event.target.value);
  }

  // SHOW HIDE ADD SWitch SLD // End

  const paramChildren = [];
  const paramOptions = slideName;
  for (let i = 0; i < paramOptions.length; i++) {
    paramChildren.push(<Option key={i} value={slideId[i]}>{i + 1}</Option>);
  }
  const elementparamChildren = [];
  const elemOptions = elementsName;
  for (let i = 0; i < elemOptions.length; i++) {
    elementparamChildren.push(
      <Option key={elemOptions[i]} value={elementsId[i]}>
        {elemOptions[i]}
      </Option>
    );
  }
  const genShow = () => {
    return (
      <>
        <Button onClick={handleShowDelete} value={''} id="deletebehaviour" minimal={true}>
          <DeleteOutlined />
        </Button>
        <Button minimal={true}>
          <EditOutlined />
        </Button>
      </>
    );
  };
  const genSwitch = () => {
    return (
      <>
        <Button onClick={handleSwitchDelete} value={''} id="deletebehaviour" minimal={true}>
          <DeleteOutlined />
        </Button>
        <Button minimal={true}>
          <EditOutlined />
        </Button>
      </>
    );
  };

  const genHide = () => {
    return (
      <>
        <Button onClick={handleHideDelete} value={''} id="deletebehaviour" minimal={true}>
          <DeleteOutlined />
        </Button>
        <Button minimal={true}>
          <EditOutlined />
        </Button>
      </>
    );
  };
  const genLink = () => {
    return (
      <>
        <Button onClick={handleLinkDelete} value={''} id="linkbehaviour" minimal={true}>
          <DeleteOutlined />
        </Button>
        <Button minimal={true}>
          <EditOutlined />
        </Button>
      </>
    );
  };

  useEffect(() => {
    setShowParams(selectedElement.event?.[0]?.param);
    setSwitchParams(selectedElement.event?.[1]?.param);
    setHideParams(selectedElement.event?.[2]?.param);
    setLinkParams(selectedElement.event?.[3]?.param);
  }, [selectedElement]);

  let elemId = selectedElement;
  let call;
  let param;
  let type;
  let text;
  if (elemId.type === 'button') {
    call = elemId.event[0]?.call;
    param = elemId.event[0]?.param;
    type = elemId.event[0]?.type;
    text = elemId.content.text;
  }

  const genShowanimation = () => {
    return (
      <Button value={''} onClick={handleDelete} minimal={true}>
        <DeleteOutlined />
      </Button>
    );
  };
  useEffect(() => {
    let animClasses = [...selectedElement.styleClasses];
    let index = animClasses.findIndex((item) => item === 'animate__animated');
    if (index === -1) {
      setClasses(animClasses);
    } else {
      // animClasses.splice(index, 1);
      setClasses(animClasses);
    }
  }, [selectedElement]);

  const children = [];
  const options = appConfig.animationList;

  for (let i = 0; i < options.length; i++) {
    children.push(<Option key={'animate__' + options[i]}>{options[i]}</Option>);
  }

  function handleChange(event) {
    let animClasses = event;
    if (event.length && event.indexOf('animate__animated') === -1) { animClasses = ['animate__animated', animClasses]; }
    setClasses('animate__animated ' + event);
  }

  function handleDelete() {
    const event = [];
    dispatch(elementUpdated(elemId, event, 'styleClasses'));
    setClasses([event]);
  }
  return (
    <Collapse>
      <Panel
        header={
          showparams.length > 0 ? (
            <p style={{ color: 'blue', fontSize: '13px' }}>{showparams.length} link present</p>
          ) : (
            'Show'
          )
        }
        key="1"
        extra={showparams.length > 0 ? genShow() : ''}>
        <Button onClick={handleShow} minimal={true} value={'show'} id="show">
          {selectedElement.type === 'img' && (
            <H6 style={{ fontSize: '11px', color: '#8d7f7f' }}>The selected object:</H6>
          )}
          {selectedElement.type === 'text' && (
            <H6 style={{ fontSize: '11px', color: '#8d7f7f' }}>The selected text:</H6>
          )}
          <Select
            mode="multiple"
            allowClear
            name="param"
            style={{ width: '190px' }}
            placeholder="Select Element"
            onChange={handleshowparamChange}>
            {elementparamChildren}
          </Select>
        </Button>
      </Panel>

      <Panel
        header={
          switchparams?.length > 0 ? (
            <p style={{ color: 'blue', fontSize: '13px' }}>1 link present</p>
          ) : (
            'SwitchSlide'
          )
        }
        key="2"
        extra={switchparams?.length > 0 ? genSwitch() : ''}>
        <Button onClick={handleSwitchsld} minimal={true} value={'switchslide'} id="switchslide">
          {selectedElement.type === 'img' && (
            <H6 style={{ fontSize: '11px', color: '#8d7f7f' }}>The selected object:</H6>
          )}
          {selectedElement.type === 'text' && (
            <H6 style={{ fontSize: '11px', color: '#8d7f7f' }}>The selected text:</H6>
          )}
          <Select
            name="param"
            style={{ width: '190px' }}
            placeholder="Select Slide"
            value={switchparams}
            onChange={handleswitchsldparamChange}>
            {paramChildren}
          </Select>
        </Button>
      </Panel>
      <Panel header={'Add Animation'} key="3" extra={classes.length > 0 ? genShowanimation() : ''}>
        {selectedElement.type === 'img' && (
          <H6 style={{ fontSize: '11px', color: '#8d7f7f', marginLeft: '10px' }}> Object Animation:</H6>
        )}
        {selectedElement.type === 'text' && (
          <H6 style={{ fontSize: '11px', color: '#8d7f7f', marginLeft: '10px' }}>Text Animation:</H6>
        )}
        <Button
          onClick={handleAddClass}
          minimal={true}
          value={'addClass'}
          id="addClass"
          style={{ width: '100%', display: 'block', marginTop: '-22px' }}>
          <p style={{ fontSize: '12px', color: ' darkgray', marginTop: '18px', marginBottom: '5px' }}>
            Choose the movement
          </p>
          <Select
            name="styleClasses"
            allowClear
            style={{ width: '190px' }}
            placeholder="Select Animation"
            value={classes}
            onChange={handleChange}>
            {children}
          </Select>
          <p style={{ fontSize: '12px', color: ' darkgray', marginBottom: '5px', marginTop: '12px' }}>
            Choose the Element
          </p>

          <Select
            name="styleClasses"
            allowClear
            style={{ width: '190px' }}
            placeholder="Select Element"
            onChange={handleaddclassparamChange}>
            {elementparamChildren}
          </Select>
        </Button>
      </Panel>
      <Panel
        header={
          hideparams.length > 0 ? (
            <p style={{ color: 'blue', fontSize: '13px' }}>{hideparams.length} link present</p>
          ) : (
            'Hide'
          )
        }
        key="4"
        extra={hideparams.length > 0 ? genHide() : ''}>
        <Button onClick={handleHide} minimal={true} value={'hide'} id="hide">
          {selectedElement.type === 'img' && (
            <H6 style={{ fontSize: '11px', color: '#8d7f7f' }}>The selected object:</H6>
          )}
          {selectedElement.type === 'text' && (
            <H6 style={{ fontSize: '11px', color: '#8d7f7f' }}>The selected text:</H6>
          )}
          <Select
            mode="multiple"
            allowClear
            name="param"
            style={{ width: '190px' }}
            placeholder="Select Element"
            // value={hideparams}
            onChange={handlehideparamChange}>
            {elementparamChildren}
          </Select>
        </Button>
      </Panel>
      <Panel
        header={
          linkparams.length > 0 ? <p style={{ color: 'blue', fontSize: '13px' }}>1 link present</p> : 'Link'
        }
        key="5"
        extra={linkparams.length > 0 ? genLink() : ''}>
        <Button onClick={handleLink} minimal={true} value={'link'} id="link">
          {selectedElement.type === 'img' && (
            <H6 style={{ fontSize: '11px', color: '#8d7f7f' }}>The selected object:</H6>
          )}
          {selectedElement.type === 'text' && (
            <H6 style={{ fontSize: '11px', color: '#8d7f7f' }}>The selected text:</H6>
          )}
          <TextArea value={linkUrl} onChange={handleLinkparamChange} rows={4} />
        </Button>
      </Panel>
    </Collapse>
  );
}

export default ElementsBehaviour;
