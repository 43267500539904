// @flow
import {
  SHOW_BEGIN,
  SHOW_END,
  ELEMENT_UPDATE,
  USER_JOIN,
  ROOM_UPDATE,
  PROJECT_INIT,
  INDEX_UPDATED,
  PROJECT_UPDATED,
  SELECTION_CHANGED,
  SELECTION_UPDATED,
  ELEMENT_CHANGED,
  ELEMENT_UPDATED,
  ELEMENT_DELETED,
  ELEMENT_COPIED,
  SLIDE_DELETED,
  SLIDE_COPIED,
  SLIDE_CHANGED,
  SLIDE_UPDATED,
  SLIDE_SELECTION_CHANGED,
  SLIDE_SELECTION_UPDATED,
  SET_HEADERSTYLEINFO,
  SLIDE_INDEX_UPDATED,
  ELEMENT_INDEX_UPDATED,
  ELEMENT_CUT_ADDED,
  DRAWER_BEHAVIOUR,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';
import _ from 'lodash';
import { newID } from '../../helpers/studioApi';

const INIT_STATE = {
  loading: false,
  project: {},
  selectedElement: {},
  selectedSlide: {},
  slides: [],
  elements: [],
  isDrawerOpen: {
    text: false,
    image: false,
    video: false,
    audio: false,
    shape: false,
    chart: false,
    table: false,
    map: false,
    code: false,
  },
  imageReplacedData: {
    isReplaced: false,
  }
};

// SHOW_BEGIN
// SHOW_END
// ELEMENT_UPDATE
// USER_JOIN
// ROOM_UPDATE

const Studio = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROJECT_INIT:
      return {
        ...state,
        projectid: action.payload.projectid,
        projectName: action.payload.projectName,
      };

    case PROJECT_UPDATED:
      return {
        ...state,
        project: action.payload.project,
        slides: action.payload.slides,
        title: action.payload.title,
        elements: action.payload.elements,
      };

    case SLIDE_SELECTION_UPDATED:
      return {
        ...state,
        selectedSlide: action.payload.slide,
      };

    case SLIDE_INDEX_UPDATED:
      return {
        ...state,
        project: {
          ...state.project,
          slides: [...action.payload.slides],
        },
        slides: [...action.payload.slides],
      };
    case ELEMENT_INDEX_UPDATED:
      return {
        ...state,
        project: {
          ...state.project,
          slides: [...action.payload.slides],
        },
        slides: [...action.payload.slides],
      };

    case ELEMENT_CHANGED:
      return { ...state, selectedElement: action.payload.show };
    case ELEMENT_UPDATED:
      return {
        ...state,
        // element: action.payload.show,
        // elementvalue: action.payload.elementvalue,
        // elementname: action.payload.elementname,
        // elementid: action.payload.elementid,
      };
    case ELEMENT_DELETED:
      return {
        ...state,
      };
    case ELEMENT_COPIED:
      return {
        ...state,
      };
    case SLIDE_DELETED:
      return {
        ...state,
      };
    case SLIDE_COPIED:
      return {
        ...state,
      };
    case SELECTION_CHANGED:
      return { ...state };
    case INDEX_UPDATED:
      return {
        ...state,
        slides: action.payload.slides,
      };
    case SELECTION_UPDATED:
      return { ...state, selectedItem: action.payload.item, show: action.payload.show };

    case SET_HEADERSTYLEINFO:
      return { ...state, headerStyle: action.payload.item };
    case ELEMENT_CUT_ADDED:
      let newElementId = newID('element');
      return {
        ...state,
        project: {
          ...state.project,
          slides: state.project.slides.map((slide) => {
            console.log('slideId', slide?.id, state.selectedSlide?.id, slide?.id === state.selectedSlide?.id);
            if (slide.id === state.selectedSlide.id) {
              console.log('slideId', slide?.id === state.selectedSlide?.id, [...slide.elements, {
                ...action.payload.element,
                id: newElementId,
              }]);
              return ({
                ...slide,
                elements: [...slide.elements, {
                  ...action.payload.element,
                  id: newElementId,
                }],
              });
            }
            return slide;
          }
          ),
        },
        selectedSlide: {
          ...state.selectedSlide,
          elements: [...state.selectedSlide.elements, {
            ...action.payload.element,
            id: newElementId,
          }],
        },
        selectedElement: {
          ...action.payload.element,
          id: newElementId,
        },
      };
    case DRAWER_BEHAVIOUR: {
      return {
        ...state,
        isDrawerOpen: {
          ...state.isDrawerOpen,
          [action.payload.type]: action.payload.value,
        },
        imageReplacedData: {
          isReplaced: action.payload.isReplaced,
          data: action.payload.data
        },
      };
    }
    default:
      return { ...state };
  }
};

export default Studio;
